import { mapUtil } from '@sw-sw/common';
import PropTypes from 'prop-types';
import React, { Component, Fragment, useContext, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import AppDivisionContext from '../../../contexts/AppDivisionContext';
import { ProjectContext } from '../../../contexts/ProjectContext';
import ProjectPermissionContext from '../../../contexts/ProjectPermissionContext';
import uploadApi from '../../../utils/api/upload';
import SiteMapCard from '../../Card/SiteMapCard';
import ConfirmationModal from '../../Shared/ConfirmationModal/ConfirmationModal';
import SuccessModal from '../../Shared/SuccessModal/SuccessModal';
import SiteMapPrint from '../../Inspections/Details/SiteMapPrint';
import PrintSiteMapsModal from '../Header/PrintSiteMapsModal';
import { ProjectPrintProvider } from '../../../contexts/ProjectPrintContext';
import AppContext from '../../../contexts/AppContext';
import _ from 'lodash';
import ResourceSearch from '../../Shared/Resource/Search';
import useResource from '../../../hooks/resource';
import UploadInput from '../../FormInput/Upload';
import { RolesContext } from '../../../contexts/RolesContext';
import FileRenameModal from '../../Shared/FileRow/FileRenameModal';
import { Modal } from '@sw-sw/lib-ui';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import projectApi from '../../../utils/api/project';

const { isSupportedMimeType } = mapUtil;
/**
 * Render cards for each site map in a project
 *
 * @todo optional canEdit
 * @todo generic 'map editor' route, at root of app, instead of route-per-map
 */
class SiteMapsUI extends Component {
  fileInputRef = React.createRef();
  state = {
    selected: null,
    showConfirmation: false,
    showArchiveConfirmation: false,
    showRestoreConfirmation: false,
    showPrintConfirmation: false,
    showRenameModal: false,
    showReplaceModal: false,
    targetMap: null,
    error: '',
    targetDoc: {},
    archiveView: false,
    isLoading: false,
  };

  static contextType = AppDivisionContext;

  get editorProps() {
    const { selected } = this.state;

    const editorProps = {
      onClose: () => this.deselect(),
    };

    if (selected !== null) {
      Object.assign(editorProps, {
        map: selected,
        title: this.props.projectName,
        subtitle: selected.name,
      });
    }

    return editorProps;
  }

  handleCardClick = (selected, isPublic) => {
    this.setState({ selected }, () => {
      const { history, projectId, inspectionId, isActiveMap } = this.props;
      const ext = isActiveMap ? 'active-map' : 'inspection';

      if (isPublic) {
        history.push(
          this.context.getPublicPath(
            `/projects/${projectId}/map/${selected.GUID}/edit?from=${ext}${this.state.archiveView ? '&archive' : ''}`,
          ),
          {
            inspectionId: inspectionId,
          },
        );
      } else {
        history.push(
          this.context.getPath(
            `/projects/${projectId}/map/${selected.GUID}/edit?from=${ext}${this.state.archiveView ? '&archive' : ''}`,
          ),
          {
            inspectionId: inspectionId,
          },
        );
      }
    });
  };

  deselect = () => {
    this.setState({ selected: null }, () => {
      const { history, match } = this.props;

      history.push(this.context.getPath(match.url));
    });
  };

  handleClick = () => {
    this.setState({ archiveView: !this.state.archiveView });
  };

  handleOrganizeClick = () => {
    if (!this.state.archiveView) {
      this.setState({
        showReorderModal: true,
        tempReorderProjectDocs: this.props.siteMaps,
      });
    } else {
      this.setState({
        showReorderModal: true,
        tempReorderProjectDocs: this.props.archivesiteMaps,
      });
    }
  };

  handleUploadClick = async (newFile) => {
    await uploadApi.project
      .create(newFile.id, this.props.projectId, this.props.docTypeId);
    return this.props.onDocumentUpload();
  };

  handleRename = (fileName) => {
    uploadApi.rename(this.state.targetDoc.GUID, fileName)
    this.setState({ showRenameModal: false })
    return this.props.onDocumentUpload()
  }

  handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      this.handleReplace(file)
    }
  };

  handleReplace = async (file) => {
    this.setState({ isLoading: true });
    uploadApi.create(file)
      .then(async (resposnse) => {
        await uploadApi.project.create(resposnse.id, this.props.projectId, this.props.docTypeId);
        let payload = {
          newDoc: {
            ...resposnse,
            project_doc_type_id: this.props.docTypeId,
            project_id: this.props.projectId,
          },
          oldDoc: this.state.targetDoc,
        };
        await uploadApi.project.replaceDocData(this.props.projectId, payload);
        this.setState({ isLoading: false });
        return this.props.onDocumentUpload();
      })
  }

  handleArchive = () => {
    uploadApi.project
      .archive(this.props.projectId, this.state.targetDoc.id, this.state.archiveView ? "Restore" : "Archive")
      .then(() => {
        if (this.state.archiveView) {
          const ele = this.props.archivesiteMaps.filter((arr) => arr.GUID === this.state.targetDoc.GUID)
          const index = this.props.archivesiteMaps.indexOf(ele[0])

          this.props.archivesiteMaps.splice(index, 1)

          this.props.setSiteMaps([...this.props.siteMaps, ele[0]])
          this.props.setArchiveSiteMaps(this.props.archivesiteMaps)
        } else {
          const ele = this.props.siteMaps.filter((arr) => arr.GUID === this.state.targetDoc.GUID)
          const index = this.props.siteMaps.indexOf(ele[0])

          this.props.siteMaps.splice(index, 1)

          this.props.setArchiveSiteMaps([...this.props.archivesiteMaps, ele[0]])
          this.props.setSiteMaps(this.props.siteMaps)
        }
        this.setState({ showArchiveConfirmation: false, showRestoreConfirmation: false });
      })
      .catch((err) =>
        this.setState({
          error:
            err.response && err.response.data && err.response.data.message
              ? err.response.data.message
              : 'An error has occurred',
        }),
      );
  };

  handleDelete = () => {
    uploadApi.project
      .destroy(this.props.projectId, this.state.targetDoc.id)
      .then(() => {
        if (this.state.archiveView) {
          const ele = this.props.archivesiteMaps.filter((arr) => arr.GUID === this.state.targetDoc.GUID)
          const index = this.props.archivesiteMaps.indexOf(ele[0])

          this.props.archivesiteMaps.splice(index, 1)
          this.props.setArchiveSiteMaps(this.props.archivesiteMaps)
        }
        else {
          const ele = this.props.siteMaps.filter((arr) => arr.GUID === this.state.targetDoc.GUID)
          const index = this.props.siteMaps.indexOf(ele[0])

          this.props.siteMaps.splice(index, 1)
          this.props.setSiteMaps(this.props.siteMaps)
        }
        this.props.onDocumentDelete(this.state.targetDoc.GUID);
        this.setState({ showConfirmation: false });
      })
      .catch((err) =>
        this.setState({
          error:
            err.response && err.response.data && err.response.data.message
              ? err.response.data.message
              : 'An error has occurred',
        }),
      );
  };

  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);

    result.splice(endIndex, 0, removed);

    return result;
  };

  handleSubmitReorderProjectDocs = async () => {
    await projectApi.sortDocs(this.state.tempReorderProjectDocs);
  };

  handleOnDragEnd = (result) => {
    if (!result.destination) return;
    if (result.destination.index === result.source.index) return;

    const reordered = this.reorder(
      this.state.tempReorderProjectDocs,
      result.source.index,
      result.destination.index,
    );

    this.setState({ tempReorderProjectDocs: reordered });
  };

  getImgSrc = _.memoize((doc) => {
    return uploadApi.getDownloadUrl(doc.GUID, 450);
  });

  render() {
    const isPublic = this.props.roleName === 'Public' || this.props.roleName === "Public No Image";

    return (
      <Fragment>
        <input
          type='file'
          ref={this.fileInputRef}
          style={{ display: 'none' }}
          onChange={this.handleFileChange}
          accept='.jpg,.jpeg,.png'
        />
        {(this.props.siteMaps && this.props.siteMaps.length) ||
        (this.props.archivesiteMaps && this.props.archivesiteMaps.length) ? (
          <>
            {!(this.props.siteMaps && this.props.siteMaps.length) &&
              !this.state.archiveView && (
                <p>No active site maps have been uploaded.</p>
              )}
            <div className='project-site-maps pure-g pure-g-with-gutters'>
              <div
                style={{
                  display: 'flex',
                  gap: '5px',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                  justifyContent: 'space-between',
                }}
              >
                <ResourceSearch
                  local={false}
                  {...this.props.searchProps}
                  style={{ flex: 2, minWidth: '285px' }}
                />
                { this.props.permCheck('create', 'Uploads') &&
                <button
                  className='card-action pure-button button-outline-blue'
                  style={{ width: '33%', minWidth: '120px', flex: 1 }}
                  onClick={this.handleOrganizeClick}
                >
                  Organize
                </button>
                }
                <button
                  className='card-action pure-button button-outline-blue'
                  style={{ width: '33%', minWidth: '120px', flex: 1 }}
                  onClick={this.handleClick}
                >
                  {this.state.archiveView
                    ? 'Active Site Maps'
                    : 'Archived Maps'}
                </button>
                {!this.state.archiveView &&
                  this.props.permCheck('create', 'Uploads') &&
                  this.props.permCheck('update', 'Project Documents') && (
                    <div
                      style={{ width: '37px', height: '37px', margin: '3px' }}
                    >
                      <UploadInput
                        onUpload={(newFile) => this.handleUploadClick(newFile)}
                        allowedTypes={['image/jpg', 'image/jpeg', 'image/png']}
                        maxFileSize={75000000}
                        imageDimensions={{
                          width: 1000,
                          height: 1000,
                        }}
                      />
                    </div>
                  )}
              </div>
              <div className='map-container'>
                {this.state.archiveView
                  ? this.props.archivesiteMaps
                      .filter((e) => {
                        if (this.props.searchValue) {
                          const mapName = e.name.toLowerCase();
                          const searchValue =
                            this.props.searchValue.query.toLowerCase();

                          return mapName.match(searchValue);
                        }

                        return e;
                      })
                      .map((doc) => (
                        <div className='pure-u-1 pure-u-md-1-3' key={doc.GUID}>
                          <SiteMapCard
                            imgSrc={this.getImgSrc(doc)}
                            title={doc.name}
                            label='Project'
                            handleImgClick={() =>
                              this.handleCardClick(doc, isPublic)
                            }
                            handleDelete={() =>
                              this.setState({
                                showConfirmation: true,
                                targetDoc: doc,
                              })
                            }
                            handleArchive={() =>
                              this.setState({
                                showRestoreConfirmation: true,
                                targetDoc: doc,
                              })
                            }
                            handleRename={() =>
                              this.setState({
                                showRenameModal: true,
                                targetDoc: doc,
                              })
                            }
                            handleReplace={() => {
                              this.setState({
                                targetDoc: doc,
                              });
                              this.fileInputRef.current.click();
                            }}
                            handlePrint={() =>
                              this.setState({
                                showPrintConfirmation: true,
                                targetDoc: doc,
                              })
                            }
                            handleDownload={() => {
                              const url = uploadApi.getDownloadUrl(doc.GUID);
                              window.open(url, '_blank');
                            }}
                            canArchive={
                              this.props.projectPermissionContext.readOnly
                                ? false
                                : this.props.permCheck('all', 'Restore')
                            }
                            isArchive={true}
                            isLoading={
                              doc === this.state.targetDoc &&
                              this.state.isLoading
                            }
                          />
                        </div>
                      ))
                  : this.props.siteMaps
                      .filter((e) => {
                        if (this.props.searchValue) {
                          const mapName = e.name.toLowerCase();
                          const searchValue =
                            this.props.searchValue.query.toLowerCase();

                          return mapName.match(searchValue);
                        }

                        return e;
                      })
                      .map((doc) => (
                        <div className='pure-u-1 pure-u-md-1-3' key={doc.GUID}>
                          <SiteMapCard
                            imgSrc={this.getImgSrc(doc)}
                            title={doc.name}
                            label='Project'
                            handleImgClick={() =>
                              this.handleCardClick(doc, isPublic)
                            }
                            handleDelete={() =>
                              this.setState({
                                showConfirmation: true,
                                targetDoc: doc,
                              })
                            }
                            handleArchive={() =>
                              this.setState({
                                showArchiveConfirmation: true,
                                targetDoc: doc,
                              })
                            }
                            handleRename={() =>
                              this.setState({
                                showRenameModal: true,
                                targetDoc: doc,
                              })
                            }
                            handleReplace={() => {
                              this.setState({
                                targetDoc: doc,
                              });
                              this.fileInputRef.current.click();
                            }}
                            handlePrint={() =>
                              this.setState({
                                showPrintConfirmation: true,
                                targetDoc: doc,
                              })
                            }
                            handleDownload={() => {
                              const url = uploadApi.getDownloadUrl(doc.GUID);
                              window.open(url, '_blank');
                            }}
                            canArchive={
                              this.props.projectPermissionContext.readOnly
                                ? false
                                : this.props.permCheck('all', 'Archive')
                            }
                            isLoading={
                              doc === this.state.targetDoc &&
                              this.state.isLoading
                            }
                          />
                        </div>
                      ))}
              </div>
            </div>
          </>
        ) : isPublic ? (
          <p>No site maps have been uploaded.</p>
        ) : (
          <>
            <p>
              No active site maps have been uploaded. Click the button below to
              upload a site map.
            </p>
            {this.props.permCheck('create', 'Uploads') &&
              this.props.permCheck('update', 'Project Documents') && (
                <div className='upload-site-maps-button'>
                  <UploadInput
                    onUpload={(newFile) => this.handleUploadClick(newFile)}
                    allowedTypes={['image/jpg', 'image/jpeg', 'image/png']}
                    maxFileSize={75000000}
                    imageDimensions={{
                      width: 1000,
                      height: 1000,
                    }}
                    showHelpText
                  />
                </div>
              )}
          </>
        )}

        <ConfirmationModal
          show={this.state.showConfirmation}
          handleClose={() => this.setState({ showConfirmation: false })}
          handleConfirm={this.handleDelete}
          title='Are you sure you want to delete this site map?'
          subTitle='This can not be undone.'
          buttonText='Delete Site Map'
        />

        <ConfirmationModal
          show={this.state.showArchiveConfirmation}
          handleClose={() => this.setState({ showArchiveConfirmation: false })}
          handleConfirm={this.handleArchive}
          title='Are you sure you want to archive this site map?'
          subTitle='This will be only visible on the archive site map page'
          buttonText='Archive Site Map'
        />

        <ConfirmationModal
          show={this.state.showRestoreConfirmation}
          handleClose={() => this.setState({ showRestoreConfirmation: false })}
          handleConfirm={this.handleArchive}
          title='Are you sure you want to restore this site map?'
          buttonText='Restore Site Map'
        />

        {this.state.showPrintConfirmation && this.props.inspectionId > 0 && (
          <SiteMapPrint
            onClose={() => this.setState({ showPrintConfirmation: false })}
            targetDoc={this.state.targetDoc}
          />
        )}

        {this.state.showPrintConfirmation && !this.props.inspectionId && (
          <ProjectPrintProvider>
            <PrintSiteMapsModal
              onClose={() => this.setState({ showPrintConfirmation: false })}
              targetDoc={this.state.targetDoc}
            />
          </ProjectPrintProvider>
        )}
        {this.state.showReorderModal && this.props.siteMaps.length ? (
          <Modal
            title='Organize Site Maps'
            show={true}
            handleClose={() => {
              this.setState({ showReorderModal: false });
            }}
            handleSubmit={async (x) => {
              await this.handleSubmitReorderProjectDocs();
              await this.props.onDocumentUpload();
              this.setState({ showReorderModal: false });
            }}
            submitBtnText='Save'
          >
            <DragDropContext onDragEnd={this.handleOnDragEnd}>
              <Droppable droppableId='documentGroup'>
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {this.state.tempReorderProjectDocs.length &&
                      this.state.tempReorderProjectDocs.map((ele, index) => {
                        return (
                          <Draggable
                            key={`${index}`}
                            draggableId={`${index}`}
                            index={index}
                          >
                            {(provide) => (
                              <div
                                ref={provide.innerRef}
                                {...provide.draggableProps}
                                {...provide.dragHandleProps}
                                style={{
                                  ...provide.draggableProps.style,
                                  left: 'auto !important',
                                  top: 'auto !important',
                                  marginBottom: '5px',
                                }}
                              >
                                <span className='document-group-reorder-modal__draggable-element'>
                                  <FontAwesomeIcon
                                    icon='grip-lines'
                                    size='sm'
                                    color='#385980'
                                  />
                                  <span className='document-group-reorder-modal__draggable-name'>
                                    {ele.name ? ele.name : ele}
                                  </span>
                                </span>
                              </div>
                            )}
                          </Draggable>
                        );
                      })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </Modal>
        ) : null}

        <FileRenameModal
          show={this.state.showRenameModal}
          file={{
            guid: this.state.targetDoc.GUID,
            name: this.state.targetDoc.name,
          }}
          onCancel={() => this.setState({ showRenameModal: false })}
          onSubmit={(fileName) => this.handleRename(fileName)}
        />

        <SuccessModal
          show={this.state.error.length > 0}
          handleClose={() => this.setState({ error: '' })}
          handleSubmit={() => this.setState({ error: '' })}
          submitBtnText='OK'
          message={this.state.error}
          title='Error replacing document'
          isAlert
        />
      </Fragment>
    );
  }
}

SiteMapsUI.propTypes = {
  /**
   * Uploads
   */
  siteMaps: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      GUID: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
    }).isRequired,
  ).isRequired,
  archivesiteMaps: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      GUID: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
    }).isRequired,
  ).isRequired,
  projectId: PropTypes.number.isRequired,
  projectName: PropTypes.string.isRequired,
  onDocumentDelete: PropTypes.func,
  onDocumentUpload: PropTypes.func,
  showActions: PropTypes.bool,
  inspectionId: PropTypes.number,
  isActiveMap: PropTypes.bool,
  permCheck: PropTypes.func,
  docTypeId: PropTypes.number,
};

SiteMapsUI.defaultProps = {
  inspectionId: 0,
};

export const SiteMaps = ({ ...props }) => {
  const history = useHistory();
  const histUrlString = history.location.pathname;
  const urlReg = RegExp('active');
  const checkRegex = urlReg.test(histUrlString);
  const rolesContext = useContext(RolesContext);
  const projectContext = useContext(ProjectContext);
  const projectPermissionContext = useContext(ProjectPermissionContext);
  const docTypes = projectContext.projectDocs.documentTypes.filter(
    (dType) => dType.name === 'Active Site Maps',
  );
  const permCheck = rolesContext.userHasPermission;
  const docs = docTypes.length ? docTypes[0].documents : [];
  const [siteMaps, setSiteMaps] = useState(docs.filter((doc) => isSupportedMimeType(doc.mime_type) && !doc.archived_at))
  const [archivesiteMaps, setArchiveSiteMaps] = useState(docs.filter((doc) => isSupportedMimeType(doc.mime_type) && doc.archived_at))
  const auth = useContext(AppContext).get('user');

  const { search } = useResource({ resource: 'divisionProjects' });
  const { query, setQuery } = search;

  const searchProps = {
    query,
    setQuery(input) {
      setQuery(input);
    },
    placeholder: 'Search Site Maps..',
  };

  useEffect(() => {
    setSiteMaps(docs.filter((doc) => isSupportedMimeType(doc.mime_type) && !doc.archived_at))
    setArchiveSiteMaps(docs.filter((doc) => isSupportedMimeType(doc.mime_type) && doc.archived_at))
  }, [docs]);

  return (
    <SiteMapsUI
      {...props}
      history={history}
      siteMaps={siteMaps}
      archivesiteMaps={archivesiteMaps}
      projectId={projectContext.project.id}
      projectName={projectContext.project.name}
      projectPermissionContext={projectPermissionContext}
      isActiveMap={checkRegex}
      roleName={auth.roleName}
      searchProps={searchProps}
      searchValue={search}
      setSiteMaps={setSiteMaps}
      setArchiveSiteMaps={setArchiveSiteMaps}
      permCheck={permCheck}
      docTypeId={docTypes.length ? docTypes[0].project_doc_type_id : null}
    />
  );
};

export default SiteMaps;
