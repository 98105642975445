
import React,{useContext} from "react";
import { FormContext, FormSchemaFields } from '@sw-sw/lib-form';
import FormModal from '../../../Shared/form/modal/FormModal';
import { UIControlType } from '@sw-sw/lib-form-control-types';

export interface IDirectionModalProps {
  onClose: () => void;
  Submit: (direction:any) => void;
}

const getSchema = () => {
    
    const schema: any = {
        direction: {
            label: 'Direction Selection',
            controlType: UIControlType.select,
            options: [
              { id: 1, name: 'Closest to Farthest' },
              { id: 2, name: 'Farthest to Closest' },
            ],
            labelKey: 'name',
            valueKey: 'id',
            validation: {
              required: true,
            },
            parse: (val: any) => Number.parseInt(val, 10),
          },
    };
    
    return schema;
    }


const DirectionModal: React.FC<IDirectionModalProps> =
  ({ onClose, Submit }) => {

    const onSubmit = async () => {
        Submit(formContext.value.direction);
    };
  
    const formContext = useContext(FormContext);


    return (
      <FormModal
        onSubmit={onSubmit}
        onCancel={onClose}
        modalProps={{
            title: 'Select direction',
          }}
      >
         <FormSchemaFields
            schema={getSchema()}
            onChange={formContext.set}
            formData={formContext.value}
            initialFormData={formContext.value}
          />
       
      </FormModal>
    );
  };

export default DirectionModal;
