import React, { useState, useEffect } from 'react';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import DragDropInputFile from '../Administration/DragDropInputFile';
import { toast } from 'react-toastify';
import { getDownloadUrl } from '../../utils/api/upload';
import userApi from '../../utils/api/user';
import { ConfirmationModal } from '@sw-sw/lib-ui';
import { ActionButtons } from '../Shared/ActionButtons';
import Loading from '../Shared/ResourceIndex/Loading';

const FileSelectionConfirmation = ({
  selectedFile,
  setSelectedFile,
  busy,
  setBusy,
  setShowUploadInput,
  replaceState,
  setReplceState,
  setUserSignature,
  setCurrentType
}: any) => {
  const uploadUserSignature = () => {
    toast('Uploading Signature!');
    setBusy(true);

    if (selectedFile) {
      const formData: any = new FormData();

      formData.append(`file`, selectedFile);
      formData.append(`type_of_signature`, 'uploaded_signature');

      if (replaceState) {
        formData.append(`is_replaced`, replaceState);
      }

      userApi
        .uploadUserSignature({ formData })
        .then((data) => {
          setBusy(false);
          if (data && data.success === true) {
            toast.success('Signature Uploaded Successfully!');
            setShowUploadInput(false);

            const imgUrl = getDownloadUrl(
              data.userSignature.uploaded_signature.GUID,
              null,
              false,
              Date.now(),
              null,
            );

            setUserSignature(imgUrl);
          } else {
            toast.error('Something went wrong, signature not uploaded!');
          }
          setSelectedFile(null);
          setReplceState(false);
          setCurrentType('uploaded_signature');
        })
        .catch((e) => {
          console.log(e);
          setSelectedFile(null);
          setReplceState(false);
        });
    } else {
      setBusy(false);
      toast.error('Something went wrong, signature not uploaded!');
      setSelectedFile(null);
      setReplceState(false);
    }
  };

  const cancelUpload = () => {
    setSelectedFile(null);
  };

  return (
    <div className='file-selection-confirm'>
      <p>File: {selectedFile.name}</p>
      <button
        className='primary'
        onClick={() => uploadUserSignature()}
        disabled={busy}
      >
        Upload & Use
      </button>
      <button onClick={() => cancelUpload()}>Cancel</button>
    </div>
  );
};

const UploadedUserSignature = ({
  imgSrc,
  handleDelete,
  handleReplace,
  currentType,
  setCurrentType,
}: {
  imgSrc: string;
  handleDelete: any;
  handleReplace: any;
  currentType: string;
  setCurrentType: any;
}) => {
  const [blobUrl, setBlobUrl] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);

  useEffect(() => {
    const fetchImage = async () => {
      try {
        setIsLoading(true);
        const response = await fetch(imgSrc);
        if (!response.ok) {
          throw new Error('Failed to fetch image');
        }
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        setBlobUrl(url);
      } catch (error) {
        console.error('Error fetching the image:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchImage();

    return () => {
      if (blobUrl) {
        URL.revokeObjectURL(blobUrl);
      }
    };
  }, [imgSrc]);

  const handleUseSignature = async () => {
    await userApi.useSignature('uploaded_signature').then((data) => {
      if (data && data.success === true) {
        toast.success('Signature Used Successfully!');
      } else {
        toast.error('Something went wrong while using Signature!');
      }
    });
    setCurrentType('uploaded_signature');
  }

  return (
    <>
      <div className='uploaded-signature-container'>
        <div className='image-holder'>
          {isLoading ? (
            <Loading />
          ) : (
            <img src={blobUrl || ''} alt='Uploaded signature' />
          )}
        </div>
        <ActionButtons
          secondary={[
            {
              icon: 'edit',
              label: 'Replace',
              onClick: () => handleReplace(),
            },
            {
              icon: 'trash',
              label: 'Delete',
              onClick: () => setShowConfirmationModal(true),
            },
          ]}
        />
        {showConfirmationModal && (
          <ConfirmationModal
            show={showConfirmationModal}
            handleClose={() => setShowConfirmationModal(false)}
            handleConfirm={() => {
              handleDelete();
            }}
            title='Are you sure you want to delete this Signature?'
            subTitle='This cannot be undone.'
            buttonText='Delete Signature'
          />
        )}
      </div>
      <div className='use-button-upload-signature'>
        <button className='primary' onClick={handleUseSignature} disabled={currentType === 'uploaded_signature'}>
          {currentType === 'uploaded_signature' ? 'In Use' : 'Use this Signature'}
        </button>
      </div>
    </>
  );
};

const UploadUserSignature = () => {
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [errorMessage, setErrorMessage] = useState<any>(null);
  const [busy, setBusy] = useState<boolean>(false);
  const [showUploadInput, setShowUploadInput] = useState<boolean>(true);
  const [replaceState, setReplceState] = useState<boolean>(false);
  const [userSignature, setUserSignature] = useState<any>();
  const [currentType, setCurrentType] = useState<string>('');

  const handleFileDrop = (file: any) => {
    if (file.size > 1024 * 1024) {
      setErrorMessage('File size should be less than 1 MB.');
      return;
    } else {
      setSelectedFile(file);
      setErrorMessage(null);
    }
  };

  const handleError = (message: string) => {
    if (message) {
      setErrorMessage(message);
    }
  };

  const handleDeleteUserSignature = () => {
    setBusy(true);
    userApi
      .deleteSignature()
      .then((data) => {
        if (data && data.success === true) {
          toast.success('Signature Deleted Successfully!');
          setShowUploadInput(true);
          setBusy(false);
        } else {
          toast.error(
            'Something went wrong while deleting Signature!',
          );
          setBusy(false);
        }
      })
      .catch(() => {
        toast.error(
          'Something went wrong while deleting Signature!',
        );
        setBusy(false);
      });
  };

  const handleReplaceUserSignature = () => {
    setShowUploadInput(true);
    setReplceState(true);
  };

  const handleReplaceCancel = () => {
    setShowUploadInput(false);
    setReplceState(false);
    setErrorMessage(null);
  };

  const loadInitialData = () => {
    return userApi
      .getUserSignature()
      .then((userSignatureResponse) => {
        setCurrentType(userSignatureResponse.userSignature.type_of_signature);
        if (userSignatureResponse && userSignatureResponse.success === true) {
          const imgUrl = getDownloadUrl(
            userSignatureResponse.userSignature.uploaded_signature.GUID,
            null,
            false,
            Date.now(),
            null,
          );

          setUserSignature(imgUrl);
          setShowUploadInput(false);
        } else {
          setShowUploadInput(true);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    loadInitialData();
  }, []);

  return (
    <div className='upload-containers'>
      {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}

      {selectedFile && selectedFile.name ? (
        <FileSelectionConfirmation
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
          busy={busy}
          setBusy={setBusy}
          setShowUploadInput={setShowUploadInput}
          replaceState={replaceState}
          setReplceState={setReplceState}
          setUserSignature={setUserSignature}
          setCurrentType={setCurrentType}
        />
      ) : !showUploadInput ? (
        <UploadedUserSignature
          imgSrc={userSignature}
          handleDelete={handleDeleteUserSignature}
          handleReplace={handleReplaceUserSignature}
          currentType={currentType}
          setCurrentType={setCurrentType}
        />
      ) : (
        <DragDropInputFile
          replaceState={replaceState}
          selectedFile={selectedFile}
          onFileDrop={handleFileDrop}
          onError={handleError}
          handleReplaceCancel={handleReplaceCancel}
          text='Signature'
        />
      )}
    </div>
  );
};

export default DragDropContext(HTML5Backend)(UploadUserSignature);
