import { CertSignature } from '@sw-sw/lib-certification';
import { FormHelpText } from '@sw-sw/lib-form';
import classnames from 'classnames';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import AppContext from '../../../contexts/AppContext';
import ProjectPermissionContext from '../../../contexts/ProjectPermissionContext';
import userApi from '../../../utils/api/user';
import { getDownloadUrl } from '../../../utils/api/upload';

export function SignatureCheckbox({
  lineType = 'unspecifiedLineType',
  onSubmit,
  enabled,
  userSignatures = [],
  errors,
  checked = undefined,
  readOnly = undefined,
}: {
  lineType?: string;
  enabled: boolean;
  onSubmit: () => Promise<void>;
  errors: string[];
  userSignatures?: CertSignature[];
  checked?: boolean;
  readOnly?: boolean;
}) {
  const appContext = useContext(AppContext);
  const projectPermissionContext = useContext(ProjectPermissionContext);

  const [fontDetails, setFontDetails] = useState<any>(null);
  const [userSignature, setUserSignature] = useState<any>(null);

  const signatureAuthorized = appContext.get(
    'user.electronicSignatureAuthorization',
  );

  const existingSignature: CertSignature & {signed_date?: string}  | null = useMemo(() => {
    const sig = userSignatures.filter((s) => s.lineType === lineType);

    return sig.length ? sig[0] : null;
  }, [userSignatures, lineType]);
  const canSign = useMemo(() => {
    return (
      enabled &&
      !existingSignature &&
      !projectPermissionContext.readOnly &&
      signatureAuthorized
    );
  }, [
    enabled,
    existingSignature,
    projectPermissionContext.readOnly,
    signatureAuthorized,
  ]);

  const electronicSignatureErrorText = (
    <>
      In order to sign documents electronically, you must consent to electronic
      signatures in your <Link to='/my-profile'>user profile</Link>.
    </>
  );

  const loadInitialData = () => {
    return userApi
      .getUserSignatureId(existingSignature?.userId, existingSignature?.signed_date)
      .then((userSignatureResponse) => {
        if (userSignatureResponse && userSignatureResponse.success === true) {
          if (
            userSignatureResponse.userSignature.type_of_signature ===
            'uploaded_signature'
          ) {
            const imgUrl = getDownloadUrl(
              userSignatureResponse.userSignature.uploaded_signature.GUID,
              null,
              false,
              Date.now(),
              null,
            );

            setUserSignature(imgUrl);
          } else if (
            userSignatureResponse.userSignature.type_of_signature ===
            'font_signature'
          ) {
            setFontDetails(userSignatureResponse.userSignature.font_type);
          }
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    if (existingSignature?.userId) loadInitialData();
  }, [existingSignature]);

  return (
    <>
      {existingSignature && (fontDetails || userSignature) ? (
        <div className='pure-u pure-u-lg-1-3 pure-u-md-1-2 pure-u-1 electronic-signature-wrapper'>
          {fontDetails && (
            <label className='signature-text'>
              <span className={`font-preview-signature-text-${fontDetails}`}>
                {existingSignature.userName}
              </span>
            </label>
          )}

          {userSignature && (
            <label className='signature-image-container'>
              <img
                className='signature-image-style'
                src={userSignature}
                alt='User Signature'
              />
            </label>
          )}
        </div>
      ) : (
        <div className='pure-u pure-u-lg-1-3 pure-u-md-1-2 pure-u-1 electronic-signature-wrapper'>
          <label className='electronic-signature'>
            <input
              type='checkbox'
              name={lineType}
              aria-labelledby={`${lineType}-label`}
              onChange={() => {
                return canSign ? onSubmit() : null;
              }}
              checked={checked || !!existingSignature}
              readOnly={readOnly || !!existingSignature || !canSign}
            />
            <span
              className={classnames('pseudo-checkbox', {
                disabled: !canSign,
              })}
            />
            <div id={`${lineType}-label`} className='label-wrapper'>
              <span>Electronic Authorization Signature</span>
              {!canSign && existingSignature ? (
                <span className='text-bold text-italic'>
                  {existingSignature.userName}
                </span>
              ) : null}
            </div>
          </label>

          {!signatureAuthorized && !existingSignature && !readOnly ? (
            <p className='error signature-error'>
              {electronicSignatureErrorText}
            </p>
          ) : errors.length && !canSign ? (
            errors.map((message, key) => (
              <FormHelpText key={key} theme='error' content={message} />
            ))
          ) : (
            <>
              <i>
                <FormHelpText
                  theme='info'
                  content={
                    'By checking the electronic authorization signature box, I agree to signing these documents electronically'
                  }
                />
              </i>
            </>
          )}
        </div>
      )}
    </>
  );
}

export default SignatureCheckbox;
