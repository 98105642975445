import xhrService from '../xhrService';

const baseUrl = `/api/maps/direction`;

type MapApi = {
    directions: (params: any) => Promise<any>;
};

const mapApi: MapApi = {
  directions: (params:any) => {
    return xhrService
      .get(`${baseUrl}`, { params })
      .then((res) => res);
  },
};

export default mapApi;
