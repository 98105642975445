import { FormSchema } from "@sw-sw/lib-form";
import { UIControlType } from "@sw-sw/lib-form-control-types";
import {
  NewQuestionGroup,
  QuestionType,
  SchemaFieldWithId,
} from "@sw-sw/lib-inspection-templates";
import React, {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  SyntheticEvent,
  useEffect,
  useState,
} from "react";
import { QueryObserverResult, RefetchOptions } from "react-query";
// import { SubmitHandlerFn } from "../components/Shared/form";
import Loading from "../components/Shared/ResourceIndex/Loading";
import {
  useCreateQuestionGroup,
  useQuestionGroups,
} from "../hooks/questionGroups";
import { useQuestionTypes } from "../hooks/questionTypes";
import { questionConfigApi } from "../utils/api/questionConfig";
import { questionGroupApi } from "../utils/api/questionGroup";
import { questionTypeConfigApi } from "../utils/api/questionTypeConfig";

export type WarningMessages = "date" | "type";

export type IInspectionTemplateEditorProps = {
  questionTypes: Array<QuestionType>;
  templateId: number;
  questionGroupIds: Array<number>;
  removeQuestionGroups: Dispatch<void>;
  refetchQuestionGroups: (options?: RefetchOptions) => Promise<
    QueryObserverResult<
      {
        inspectionTemplateId: number;
        groupIds: number[];
      },
      unknown
    >
  >;
  // addNewQuestionGroup: SubmitHandlerFn;
  addNewQuestionGroup: (data: NewQuestionGroup, event?: SyntheticEvent) => Promise<{ id: number; }>;
  defaultSchema: FormSchema;
  buildSchema: (defaultSchemaData: Array<SchemaFieldWithId>) => FormSchema;

  /** Work on refactoring this and removing mappedAndSortedQuesitonGroups.
   * The data should come from the api already sorted.
   */
  mappedAndSortedQuestionGroups: Array<{ id: number; order: number }>;
  addSortedQuestionGroup: (data: { id: number; order: number }) => void;
  removeSortedQuestionGroup: (id: number) => void;
  swapQuestionGroups: (groupId: number, direction: string) => void;
  questionsWithOptions: Array<number>;
  groupedQuestionTypeConfigData: Array<any>;
  questionTypeConfigData: Array<any>;
  questionConfigData: Array<any>;
  getNextQuestionGroupOrder: () => number;
  warnings: Array<WarningMessages>;
  setWarnings: Dispatch<SetStateAction<WarningMessages[]>>;
};

const throwError = () => {
  throw new Error("TemplateEditorProvider not loaded");
};

const Context = React.createContext<IInspectionTemplateEditorProps>({
  questionTypes: [],
  templateId: 0,
  questionGroupIds: [],
  refetchQuestionGroups: throwError,
  removeQuestionGroups: throwError,
  addNewQuestionGroup: throwError,
  defaultSchema: {},
  buildSchema: throwError,
  mappedAndSortedQuestionGroups: [],
  addSortedQuestionGroup: throwError,
  removeSortedQuestionGroup: throwError,
  swapQuestionGroups: throwError,
  questionsWithOptions: [],
  groupedQuestionTypeConfigData: [],
  questionTypeConfigData: [],
  questionConfigData: [],
  getNextQuestionGroupOrder: throwError,
  warnings: [],
  setWarnings: throwError,
});

export const TemplateEditorProvider: React.FC<
  PropsWithChildren<{ initialValue: number }>
> = ({ initialValue: templateId, children }) => {
  const questionGroupsQuery = useQuestionGroups(templateId);
  const newQuestionGroupQuery = useCreateQuestionGroup(templateId);
  const questionTypesQuery = useQuestionTypes();
  const [defaultSchema, setDefaultSchema] = useState<FormSchema>({});
  const [mappedAndSortedQuestionGroups, setMappedAndSortedQuestionGroups] =
    useState<Array<{ id: number; order: number }>>([]);
  const [questionsWithOptions, setQuestionsWithOptions] = useState<
    Array<number>
  >([]);
  const [groupedQuestionTypeConfigData, setGroupedQuestionTypeConfigData] =
    useState<Array<any>>([]);
  const [questionTypeConfigData, setQuestionTypeConfigData] = useState<
    Array<any>
  >([]);
  const [questionConfigData, setQuestionConfigData] = useState<Array<any>>([]);
  const [warnings, setWarnings] = useState<Array<WarningMessages>>([
    "date",
    "type",
  ]);

  const excludedQuestionTypes = [
    "Date + Status",
    "Installed + Maintenance + Location",
    "Radio Group Text Area Deprecated",
    "Radio Group Text Area", // deprecated
    "Single Checkbox",
    "Yes/No + date",
  ];

  const addNewQuestionGroup = async (newQuestionGroup: NewQuestionGroup): Promise<{ id: number; }> => {
    return new Promise((yes, no) => {
      newQuestionGroupQuery.mutate(newQuestionGroup, {
        onSuccess: (r) => {
          yes(r);
          questionGroupsQuery.refetch();
        },
        onError: no,
      });
    });
  };

  const buildSchema = (defaultSchemaData: Array<SchemaFieldWithId>) => {
    const requiredFields = ["input_label"];

    const schema: FormSchema = {};

    schema["question_type_id"] = {
      label: "Question Type",
      controlType: UIControlType.select,
      validation: {
        required: true,
      },
      options: questionTypesQuery.data
        ? questionTypesQuery.data.filter(
          qt => !excludedQuestionTypes.includes(qt.label),
        )
        : [],
      labelKey: "label",
      valueKey: "id",
      placeholder: "Select a Question Type",
      disabled: true,
    };

    schema["options"] = {
      label: "Options",
      controlType: UIControlType.optionList,
      disabled: true,
    };

    defaultSchemaData.forEach((item: any) => {
      schema[item.fieldName] = {
        label: item.fieldLabel,
        controlType: item.controlType,
        style: { display: item.dataSource === "QuestionFeature" ? "none": "block"},
        validation: requiredFields.includes(item.fieldName)
          ? { required: true }
          : {},
      };

      if (item.description) {
        schema[item.fieldName].toolTipOptions = {
          message: item.description,
        };
      }
    });

    schema["force_update"] = {
      controlType: UIControlType.checkbox,
      label: "Force Update",
      style: { display: "none" },
      toolTipOptions: {
        message:
          "When selected, this update will be made to all previously submitted inspection forms. When left un-selected this update will only apply to inspection forms moving forward.",
      },
    };

    return schema;
  };

  const addSortedQuestionGroup = (questionGroup: {
    id: number;
    order: number;
  }) => {
    const index = mappedAndSortedQuestionGroups
      .map(_ => _.id)
      .indexOf(questionGroup.id);

    const arrayCopy = [...mappedAndSortedQuestionGroups];

    if (index >= 0) {
      arrayCopy[index] = questionGroup;
    } else {
      arrayCopy.push(questionGroup);
    }

    setMappedAndSortedQuestionGroups(
      arrayCopy.sort((a, b) => (a.order < b.order ? -1 : 1)),
    );
  };

  const removeSortedQuestionGroup = (id: number) => {
    const index = mappedAndSortedQuestionGroups.map(_ => _.id).indexOf(id);
    const arrayCopy = [...mappedAndSortedQuestionGroups];

    if (index >= 0) {
      setMappedAndSortedQuestionGroups(arrayCopy.splice(index, 1));
    }
  };

  const swapQuestionGroups = async (groupId: number, direction: string) => {
    const index = mappedAndSortedQuestionGroups.findIndex(
      _ => _.id === groupId,
    );

    if (index >= 0) {
      await questionGroupApi.swap(
        templateId,
        groupId,
        mappedAndSortedQuestionGroups[
          direction === "up" ? index - 1 : index + 1
        ].id,
      );
    }
  };

  const getNextQuestionGroupOrder = () => {
    if (mappedAndSortedQuestionGroups.length > 0) {
      return Math.max(...mappedAndSortedQuestionGroups.map(_ => _.order)) + 1;
    } else {
      return 1;
    }
  };

  useEffect(() => {
    const loadData = async () => {
      const questionConfig = await questionConfigApi.index();
      const questionTypeConfig = await questionTypeConfigApi.index();
      const schema = buildSchema(questionConfig);

      const groupedQuestionTypeConfig = questionTypeConfig.reduce(
        (acc: any, curr) => {
          if (!acc[curr.questionTypeId]) {
            acc[curr.questionTypeId] = [];
          }

          if (curr.fieldName !== "options") {
            acc[curr.questionTypeId].push(curr);
          }

          return acc;
        },
        {},
      );

      setGroupedQuestionTypeConfigData(groupedQuestionTypeConfig);
      setQuestionConfigData(questionConfig);
      setQuestionTypeConfigData(questionTypeConfig);
      setQuestionsWithOptions(questionTypeConfig.reduce(
        (acc: any, curr) =>
          curr.fieldName === "options"
            ? acc.concat(curr.questionTypeId)
            : acc,
        [],
      ));

      setDefaultSchema(schema);
    };

    if (questionTypesQuery.isFetched && questionTypesQuery.data) {
      loadData();
    }
  }, [questionTypesQuery.data]);

  if (questionGroupsQuery.isLoading || !questionGroupsQuery.data) {
    return <Loading />;
  }

  return (
    <Context.Provider
      value={{
        questionTypes: questionTypesQuery.data || [],
        templateId,
        questionGroupIds: questionGroupsQuery.data.groupIds,
        refetchQuestionGroups: questionGroupsQuery.refetch,
        removeQuestionGroups: questionGroupsQuery.remove,
        addNewQuestionGroup,
        defaultSchema,
        buildSchema,
        mappedAndSortedQuestionGroups,
        addSortedQuestionGroup,
        removeSortedQuestionGroup,
        swapQuestionGroups,
        questionsWithOptions,
        groupedQuestionTypeConfigData,
        questionTypeConfigData,
        questionConfigData,
        getNextQuestionGroupOrder,
        warnings,
        setWarnings,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default Context;
