import { LoadingMessage } from '@sw-sw/lib-ui';
import { find } from 'lodash';
import React, { useContext, useEffect } from 'react';
import { useQuery } from 'react-query';
import { InspectionContext } from '../../../contexts/InspectionContext';
import findingApi from '../../../utils/api/finding';
import FindingCard from './FindingCard';

export default function FindingListing() {
  const inspectionContext = useContext(InspectionContext);

  if (!inspectionContext.inspection.id) {
    throw new Error('inspection must be loaded in InspectionContext');
  }

  const findingsQuery = useQuery({
    queryFn: () => findingApi.index(inspectionContext.inspection.id),
    queryKey: ['inspectionFindings', inspectionContext.inspection.id],
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  useEffect(() => {
    if (!findingsQuery.isFetching) {
      let findings = inspectionContext.findings.map((finding) => {
        return {
          ...finding,
          ...(find(findingsQuery.data, { id: finding.id }) || {}),
        };
      });

      if(Array.isArray(findings) && findings.length && findings[0].disable_carry_overs){
        findings = findings.filter((ele => {
          if(ele.InspectionFindingModel){
            return ele.InspectionFindingModel.carried_over === false
          }
  
          return true
        }));
      }
      inspectionContext.setFilteredFindings(findings);
    }
  }, [findingsQuery.isFetching]);

  return (
    <section className='pure-g findings-listing'>
      {(findingsQuery.isLoading || findingsQuery.isError) ? (
        <LoadingMessage what='Findings' className='pure-u pure-u-1-1' />
      ) : (!inspectionContext.filteredFindings.length ? (
        <p className='pure-u pure-u-1-1'>No findings</p>
      ) : (
        inspectionContext.filteredFindings
          .sort((a, b) => b.number - a.number)
          .map((finding, index) => (
            <FindingCard key={index} finding={finding} />
          ))
      ))}      
    </section>
  );
}

FindingListing.propTypes = {};
