import React, { useContext, useState } from "react";
import { isEmpty, values } from "lodash";
// import { Changelog } from "@sw-sw/common";
import { useProjectLog } from "../../../hooks/projectDetails";
import Loading from "../../Shared/ResourceIndex/Loading";
import ChangeLog from "../Details/ChangeLog";
import PrecipitationLog from "./PrecipitationLog/PrecipitationLog";
import Select from "react-select";
import { FormContextProvider } from "@sw-sw/lib-form";
import DocumentsLog from "./DocumentsLog/DocumentsLog";
import { RolesContext } from "../../../contexts/RolesContext";
import TenantStore from "../../../contexts/TenantContext"
import AppContext from "../../../contexts/AppContext";
import FindingsReport from "./FindingsReport/FindingsReport";
import { useHistory } from 'react-router-dom';

export interface IProjectLogProps {
  projectId: number;
  isEVP: boolean;
}

const ProjectLog: React.FC<IProjectLogProps> = ({ projectId, isEVP }) => {
  const projectLogQuery = useProjectLog(projectId);
  const [logType, setLogType] = useState("Site Map Log")
  const permCheck = useContext(RolesContext).userHasPermission;
  const userCanAccessDocumentsLog = permCheck('all', 'Documents Log');
  const canTenantEdit = useContext(TenantStore)
  const isEnabledForTenant = canTenantEdit.tenantHasFeature('Project Documents Log')
  const appContext = useContext(AppContext);
  const isSuperAdmin = appContext.get("user").roleName === 'Super Admin';
  const history = useHistory();
  const isPublic = history.location.pathname.startsWith("/public")

  /*
  * "Site Map Log" was formarly known as "Project Log"
  * within code, along with "Site Map Log", also look for the words "Project Log"
  */
  const alwaysAvailableOptions = (isPublic && isEVP) ? [
    { value: 1, label: 'Site Map Log' }
  ] : [
    { value: 1, label: 'Site Map Log' },
    { value: 2, label: 'Precipitation Log' },
    { value: 3, label: 'Findings Report' }
  ]
  const conditionallyAvailableOptions = [
    { value: 4, label: 'Documents Log' }
  ]

  const showConditionalOptions = isSuperAdmin ? true : (userCanAccessDocumentsLog && isEnabledForTenant ? true : false)
  const logTabOptions = showConditionalOptions ? [...alwaysAvailableOptions, ...conditionallyAvailableOptions] : [...alwaysAvailableOptions]

  const onChangeFunc = (data: any) => {
    setLogType(data.label)
  }

  if (!projectLogQuery.data) {
    return <Loading what="Site Map Log" />;
  }

  const changeLogDisplay = () => {
    if (values(projectLogQuery.data).every(value => isEmpty(value))) {
      return <p>There are no logs for this project</p>;
    }

    return <ChangeLog changelogs={projectLogQuery.data} projectId={projectId} />
  }

  const displayedLogType = () => {
    switch (logType) {
      case 'Site Map Log': return changeLogDisplay()

      case 'Precipitation Log': return (
        <FormContextProvider>
          <PrecipitationLog />
        </FormContextProvider>
      )

      case 'Documents Log': return (
        <FormContextProvider>
          <DocumentsLog />
        </FormContextProvider>
      )

      case 'Findings Report': return (
        <FormContextProvider>
          <FindingsReport />
        </FormContextProvider>
      )

      default: return <ChangeLog changelogs={projectLogQuery.data} projectId={projectId} />
    }
  }

  return (
    <section className="project-log">
      <div className="project-log__select-wrapper">
        <Select
          closeMenuOnSelect={true}
          defaultValue={logTabOptions[0]}
          options={logTabOptions}
          onChange={(data) => { onChangeFunc(data) }}
          isClearable={false}
          className='project-log__select'
          classNamePrefix='project-log'
          isSearchable={false}
        />
      </div>
      <div>
        {displayedLogType()}
      </div>
    </section>
  );
};

export default ProjectLog;
