import {
  faArchive,
  faBars,
  faCheckCircle,
  faCircleNotch,
  faCloudDownloadAlt,
  faEdit,
  faFileImport,
  faFont,
  // faPrint,
  faTrash,
  faTrashRestore,
  faEye
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TooltipContext } from "@sw-sw/lib-ui";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useDrag, useDrop } from "react-dnd7";
import { useDropzone } from "react-dropzone";
import AppContext from "../../../contexts/AppContext";
import {
  FileSizeValidation,
  FileTypeValidation,
  ImageDimensionValidation,
  UploadFileValidation,
} from "../../../utils/file";
import SuccessModal from "../../Shared/SuccessModal/SuccessModal";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import Dropdown, { MenuItem } from "../Dropdown";
// import printApi from "../../../utils/api/print";
// import { toast } from "react-toastify";

const DATE_FORMAT = "MM-DD-YYYY";
const MIME_TYPE_STRIP_REGEX = /\/([a-z-]+)/;

const uploadFileValidation = UploadFileValidation({
  fileSizeValidation: FileSizeValidation(),
  fileTypeValidation: FileTypeValidation(),
  imageDimensionValidation: ImageDimensionValidation(),
});

const UploadState = Object.freeze({
  NOOP: "NOOP",
  WAITING: "WAITING",
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
});

export default function FileRow({
  permCheck,
  label,
  canDownload,
  downloadUrl,
  handleEdit,
  handleRename,
  // canPrint,
  // handlePrint,
  canReplace,
  canRename,
  canDelete,
  canRestore,
  canArchive,
  handleRestore,
  handleArchive,
  canEdit,
  handleReplace,
  document,
  handleDelete,
  handleDownload,
  accept = ["image/png", "image/jpeg", "application/pdf"],
  maxFileSize,
  minimumImageDimensions = {
    height: 20,
    width: 20,
  },
  // parentRef,
  onMove,
  // onStop,
  onDrop,
  index,
  documentGroupName,
  canDrag,
  projectId,
  document_date,
  isProjectDocumentsPage,
  selectedDocument,
  setSelectedDocument,
  isDocType,
  userCanMove
}) {
  const [uploadState, setUploadState] = useState(UploadState.NOOP);
  const [uploadErrors, setUploadErrors] = useState([]);
  const [isShowingErrorModal, setShowingErrorModal] = useState(false);
  const appContext = useContext(AppContext)
  const [checked, setChecked] = useState(false);

  const handleChange = (event) => {
    if(checked === false){
      setSelectedDocument([...selectedDocument, JSON.parse(event.target.value)])
    }
    else{
      setSelectedDocument(selectedDocument.filter(ele => ele.id!== JSON.parse(event.target.value).id))
    }

    setChecked(!checked);
  };

  // const [windowOpen, setWindowOpen] = useState(false);
  // const [downloadDisable, setDownloadDisable] = useState(false)

  // useEffect(() => {
  //   const disable = localStorage.getItem("download")

  //   setDownloadDisable(disable && disable !== "false" ? true : false)
  // }, [])
  const dropzone = useDropzone({
    accept,
    async onDropAccepted(acceptedFiles) {
      const errors = await uploadFileValidation.validate(acceptedFiles[0], {
        maxFileSize: maxFileSize,
        imageDimensions: minimumImageDimensions,
        allowedTypes: accept,
      });

      if (errors.length) {
        setUploadErrors(errors.map(error => error.message));
        setShowingErrorModal(true);

        return;
      }

      setUploadState(UploadState.WAITING);
      await handleReplace(acceptedFiles.pop());
      setUploadState(UploadState.SUCCESS);
    },
    multiple: false,
  });
  const [showModal, setShowModal] = useState(false);
  const context = useContext(TooltipContext);
  const dropRef = useRef(null);
  const triggerRef = useRef(null);
  const [, drop] = useDrop({
    accept: documentGroupName,
    hover(item, monitor) {
      if (!dropRef.current) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = dropRef.current.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      onMove({
        dragIndex,
        hoverIndex,
        document,
        index: item.index,
      });
      item.document = document;
      item.dragIndex = dragIndex;
      item.hoverIndex = hoverIndex;
      item.index = hoverIndex;
    },
    drop({ dragIndex, hoverIndex, index }, monitor) {
      onDrop({
        dragIndex,
        hoverIndex,
        document,
        index,
      });
    },
  });
  const [{ opacity }, drag, preview] = useDrag({
    item: {
      type: documentGroupName,
      index,
      document,
    },
    collect(monitor) {
      return {
        opacity: monitor.isDragging() ? 0 : 1,
      };
    },
  });

  const created = useMemo(
    () => moment(document.createdAt).format(DATE_FORMAT),
    [document.createdAt],
  );
  const updated = useMemo(
    () => moment(document.updatedAt).format(DATE_FORMAT),
    [document.updatedAt],
  );
  const type = useMemo(
    () => document.doc_type ? document.upload.mime_type.match(MIME_TYPE_STRIP_REGEX)[1]
    : document.mime_type.match(MIME_TYPE_STRIP_REGEX)[1],
    [document.mime_type],
  );

  // const userCanPrint = canPrint;
  const userCanDownload = canDownload;
  const userCanReplace = canReplace && permCheck("update", "Project Documents Replace");
  const userCanRename = canRename && permCheck("update", label);
  const userCanEdit = canEdit && permCheck("update", label);
  const userCanDelete = canDelete && permCheck("delete", label);

  drop(dropRef);
  drag(triggerRef);

  const docTypesWithCheckboxExcluded = ["Regulations", "Archived Maps", "Active Site Maps"]

  useEffect(() => {
    if (uploadState === UploadState.SUCCESS) {
      setTimeout(() => {
        setUploadState(UploadState.NOOP);
      }, 1600);
    }
  }, [uploadState]);

  // const handlePrintWS = () => {
  //   if (!downloadDisable) {
  //     toast("Download may take a few minutes, please be patient")
  //     printApi.init().then(() => {
  //       localStorage.setItem("download", true)
  //       setDownloadDisable(true)
  //       printApi.run("print", { targetDocId: document.GUID, projectId },
  //         (payload) => {
  //           if (payload.status === 'progress') {
  //             console.log("progress");
  //           }

  //           if (payload.url && !windowOpen) {
  //             setWindowOpen(true)
  //             window.open(payload.url, "_blank")
  //             localStorage.setItem("download", false)
  //             setDownloadDisable(false)
  //           }

  //           if (payload.jobId) {
  //             console.log(payload.jobId);
  //           }
  //         },
  //         projectId
  //       )
  //     })
  //   } else {
  //     toast("Download process is on going wait for it to complete")
  //   }
  // }

  return (
    <>
      {/* eslint-disable-next-line react/jsx-no-duplicate-props */}
      <tr className="file-row" ref={dropRef} ref={preview} style={{ opacity }}>
        {(isProjectDocumentsPage && userCanMove && !docTypesWithCheckboxExcluded.includes(documentGroupName)) ? 
          <td style={{ width: "5px"}}>
            <input 
              value={JSON.stringify(document)}
              onChange={handleChange}
              checked={checked}
              type="checkbox"
            />
          </td>
          : <></>
        }
        
        <td
          className="file-row__column file-row__column--fill"
          data-tip={document.name}
          data-delay-show="125"
          data-event="click"
          onMouseLeave={context.hideToolTip}
        >
          {document.doc_type ? document.upload.name : document.name}
        </td>
        <td className="file-row__column file-row__column--type">{type}</td>
        {document_date || appContext.get("user").roleName === 'Admin'? 
        <><td className="file-row__column file-row__column--hide-on-mobile file-row__column--auto-width">
          {created}
        </td>
        <td className="file-row__column file-row__column--hide-on-mobile file-row__column--auto-width">
          {updated}
        </td></> : null}

        <td className="file-row__column file-row__column--action file-row__column--auto-width">
         {isDocType ? null : <Dropdown
            label={
              <FontAwesomeIcon
                fixedWidth={true}
                spin={uploadState === UploadState.WAITING}
                icon={
                  uploadState === UploadState.WAITING
                    ? faCircleNotch
                    : uploadState === UploadState.SUCCESS
                      ? faCheckCircle
                      : faBars
                }
              />
            }
            disabled={uploadState === UploadState.WAITING}
          >

            {userCanDownload ? (
              < MenuItem
                onClick={type === "pdf" ? handleDownload : () => {
                  window.open(downloadUrl, "_blank");
                }}
              >
                <FontAwesomeIcon icon={faCloudDownloadAlt} fixedWidth />{" "}
                Download
              </MenuItem>
            ) : null}

            {type === "pdf" ?
              <MenuItem onClick={handleEdit}>
                <FontAwesomeIcon icon={((userCanEdit)? faEdit : faEye)} fixedWidth /> {userCanEdit ? "Edit" : "View"}
              </MenuItem> : null
            }

            {userCanRename ? (
              <MenuItem onClick={handleRename}>
                <FontAwesomeIcon icon={faFont} fixedWidth /> Rename
              </MenuItem>
            ) : null}

            {canArchive ? (
              <MenuItem onClick={handleArchive}>
                <FontAwesomeIcon icon={faArchive} fixedWidth /> Archive
              </MenuItem>
            ) : null}

            {canRestore ? (
              <MenuItem onClick={handleRestore}>
                <FontAwesomeIcon icon={faTrashRestore} fixedWidth /> Restore
              </MenuItem>
            ) : null}

            {userCanReplace ? (
              <MenuItem {...dropzone.getRootProps()}>
                <FontAwesomeIcon icon={faFileImport} fixedWidth /> Replace
                <input {...dropzone.getInputProps()} />
              </MenuItem>
            ) : null}

            {userCanDelete ? (
              <MenuItem onClick={() => setShowModal(true)}>
                <FontAwesomeIcon icon={faTrash} fixedWidth /> Destroy
              </MenuItem>
            ) : null}
          </Dropdown>}
        </td >
      </tr >

      <ConfirmationModal
        show={showModal}
        handleClose={() => setShowModal(false)}
        handleConfirm={() => {
          setShowModal(false);
          // console.log("handleDelete", handleDelete());
          handleDelete();
        }}
        title="Are you sure you want to delete this document?"
        subTitle="This can not be undone."
        buttonText="Delete Document"
      />

      <SuccessModal
        show={isShowingErrorModal}
        handleClose={() => setShowingErrorModal(false)}
        handleSubmit={() => setShowingErrorModal(false)}
        submitBtnText="OK"
        messages={uploadErrors}
        title="Error"
        isAlert
      />
    </>
  );
}

FileRow.propTypes = {
  downloadUrl: PropTypes.string.isRequired,
  canPrint: PropTypes.bool,
  canDelete: PropTypes.bool,
  canEdit: PropTypes.bool,
  canReplace: PropTypes.bool,
  handleDelete: PropTypes.func,
  handleEdit: PropTypes.func,
  handleRename: PropTypes.func,
  handleReplace: PropTypes.func,
  handlePrint: PropTypes.func,
  label: PropTypes.string,
  permCheck: PropTypes.func.isRequired,
  document: PropTypes.object,
  isLoading: PropTypes.bool,
  document_date: PropTypes.bool,
  selectedDocument: PropTypes.array,
  setSelectedDocument: PropTypes.func,
  canMove: PropTypes.bool,
};
