import React, { useEffect, useContext, useState } from 'react';
import projectApi from '../../../utils/api/project';
import { ProjectContext } from '../../../contexts/ProjectContext';
import moment from 'moment';

const NOAAWeatherTable = () => {
  const projectContext: any = useContext(ProjectContext);

  const [weatherData, setWeatherData] = useState<any>([]);
  const [weatherDataLoading, setWeatherDataLoading] = useState<boolean>(false);
  const [columns, setColumns] = useState<any>([]);

  useEffect(() => {
    if (projectContext.project.forecast_weather) {
      const currentDate = moment()
        .tz(projectContext.project.timezone)
        .format('MM/DD/YYYY');

      const formattedMinDate = currentDate;

      const maxDate = moment()
        .add(7, 'd')
        .tz(projectContext.project.timezone)
        .format('MM/DD/YYYY');

      const formattedMaxDate = maxDate;

      setWeatherDataLoading(true);

      projectApi
        .getNOAAWeatherData(projectContext.project.id, {
          formattedMinDate: formattedMinDate,
          formattedMaxDate: formattedMaxDate,
        })
        .then((response: any) => {
          setWeatherDataLoading(false);

          if (response.length === 0) {
            setWeatherData([]);
            setColumns([]);
          }
          if (response.length > 0) {
            const columnsData = Object.keys(response[0]).filter(
              (key) =>
                key !== 'id' &&
                key !== 'projectId' &&
                key !== 'fetchedOn' &&
                key !== 'createdAt' &&
                key !== 'updatedAt',
            );

            setColumns(columnsData);
            setWeatherData(response);
          }
        })
        .catch((error: any) => {
          setWeatherDataLoading(false);
          setWeatherData([]);
          setColumns([]);
          console.log(error);
        });
    } else {
      setWeatherData([]);
      setColumns([]);
    }
  }, [projectContext.project.forecast_weather, projectContext.project.id, projectContext.project.timezone]);

  if (weatherDataLoading) {
    return <div>Loading...</div>;
  }

  if (!projectContext.project.forecast_weather || !weatherData.length) {
    return <div></div>;
  }

  return (
    <>
      <div>
        <h3>NOAA Weather Forecast</h3>
        <div style={{ textAlign: 'center' }}>
          <h4>
            Forecast for {projectContext.project.address.latitude},{' '}
            {projectContext.project.address.longitude}
          </h4>
          <h4>
            Issued by National Weather Service{' '}
            <a href='https://www.weather.gov/PQR'>WFO PQR</a>
          </h4>
          <h4>
            {moment(weatherData[0].fetchedOn)
              .tz(projectContext.project.timezone)
              .format('hhmm A z ddd MMM DD YYYY')}
          </h4>
        </div>
      </div>
      <div style={{ overflowX: 'scroll', scrollbarWidth: 'none' }}>
        <table
          style={{ borderCollapse: 'collapse', border: '1px solid lightgray' }}
        >
          <tbody>
            {columns.map((column: any) => (
              <tr key={column}>
                <td
                  style={{
                    fontWeight: 'bold',
                    padding: '5px',
                    border: '1px solid lightgray',
                    position: 'sticky',
                    left: 0,
                    backgroundColor: '#ddd',
                  }}
                >
                  {column}
                </td>
                {weatherData.map((entry: any, index: any) => {
                  if (
                    (column === 'High (°F)' ||
                      column === 'Low (°F)' ||
                      column === 'Date') &&
                    index % 4 === 0
                  ) {
                    return (
                      <td
                        key={index}
                        style={{
                          padding: '5px',
                          textAlign: 'center',
                          border: '1px solid lightgray',
                          fontWeight: column === 'Date' ? 'bold' : '',
                          color:
                            column === 'High (°F)'
                              ? 'red'
                              : column === 'Low (°F)'
                              ? 'blue'
                              : '',
                        }}
                        colSpan={4}
                      >
                        {entry[column]}
                      </td>
                    );
                  } else if (
                    (column === 'High (°F)' ||
                      column === 'Low (°F)' ||
                      column === 'Date') &&
                    index % 4 !== 0
                  ) {
                    return null;
                  }

                  return (
                    <td
                      key={index}
                      style={{
                        textAlign: 'center',
                        padding: '5px',
                        border: '1px solid lightgray',
                        color: column === 'Time' ? 'green' : '',
                        textTransform:
                          column === 'Weather' ? 'capitalize' : 'inherit',
                      }}
                    >
                      {entry[column]}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default NOAAWeatherTable;
