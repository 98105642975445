import React, { useContext, useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  // faEllipsisV,
  faExternalLinkAlt,
  faSort,
  faRedoAlt
} from "@fortawesome/free-solid-svg-icons";
import { AppDivisionContext } from "../../../../contexts/AppDivisionContext";
import { customDashboard } from "../../../../utils/api/dashboard";
import { Link } from "react-router-dom";
import Loading from "../../../../components/Shared/ResourceIndex/Loading";
import DashboardContext from "../../../../contexts/DashboardContext";
import EllipsisPopover from '../../shared/EllipsisPopover/EllipsisPopover'
import { Tooltip } from "@mui/material";
import mapApi from "../../../../utils/api/map";
import DirectionModal from "../InspectionsDue/DirectionModal";
import { FormContextProvider } from '@sw-sw/lib-form';



const OpenFindings = ({ providedProps, changeShowMore, mdSize }) => {
  const [search, setSearch] = useState('')
  const { getPath } = useContext(AppDivisionContext);
  const [clientIsAscending, setClientIsAscending] = useState(true)
  const [siteIsAscending, setSiteIsAscending] = useState(true)
  const { displayStatsForOF, isLoadingForOF, allProjectsForOF, setDisplayStatsForOF, setIsLoadingForOF, findingTypeListForOF, fetchStatsFuncForOF } = useContext(DashboardContext)
  const widgetRef = useRef(0);
  const [widgetWidth, setWidgetWidth] = useState(widgetRef.current.clientWidth)
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [openDirectionModal, setOpenDirectionModal] = useState(false);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  useEffect(() => {
    function handleResize() {
      setWidgetWidth(widgetRef.current.clientWidth)
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  })

  const handleSelect = (event) => {
    setIsLoadingForOF(true)
    customDashboard.fetchWithParams(getPath('/openFindings/getOpenFindingStats'), { "type": event.target.value })
      .then(res => {
        setDisplayStatsForOF(res.data.statistics)
        setIsLoadingForOF(false)
      })
      .catch((error) => console.log(error))
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  //SEARCH
  useEffect(() => {
    setSelectedProjects([])
    const updatedList = allProjectsForOF && [...allProjectsForOF].filter((el) => {
      return el.siteName.toLowerCase().includes(search.toLowerCase())
    })

    setDisplayStatsForOF(updatedList)
  }, [search])

  //SORT
  const sortBySiteName = () => {
    const updatedList = displayStatsForOF.sort((a, b) => {
      let A = a.siteName.toString().toLowerCase()

      let B = b.siteName.toString().toLowerCase()

      if (siteIsAscending === true) {
        setSiteIsAscending(!siteIsAscending)

        return A > B ? -1 : 1 // descending
      }

      else {
        setSiteIsAscending(!siteIsAscending)

        return A > B ? 1 : -1 //ascending
      }
    })

    setDisplayStatsForOF(updatedList)
  }

  const sortByClientName = () => {
    const updatedList = displayStatsForOF.sort((a, b) => {
      let A = a.clientName.toString().toLowerCase()

      let B = b.clientName.toString().toLowerCase()

      if (clientIsAscending === true) {
        setClientIsAscending(!clientIsAscending)

        return A > B ? -1 : 1 // descending
      }

      else {
        setClientIsAscending(!clientIsAscending)

        return A > B ? 1 : -1 //ascending
      }
    })

    setDisplayStatsForOF(updatedList)
  }


  let oneColumnHeader = {
    // gridTemplateAreas: '"title title title title . bars bars bars bars bars bars ellipsis"'
  }

  const handleCheckboxChange = (project) => {
    setSelectedProjects(prevSelectedProjects =>
      prevSelectedProjects.find(selectedProject=>selectedProject.id === project.id)
        ? prevSelectedProjects.filter(selectedProject => selectedProject.id !== project.id)
        : [...prevSelectedProjects, project]
    );
  };

  const GetDirection = async (direction) => {
    const addresses = selectedProjects.map((project) => {
      return project.address ? project.address : project;
    });

    if (!addresses.length) {
      return;
    }

    try {
      const position = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      });

        addresses.unshift({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
        let waypoints = addresses
          .map((add) => `${add.latitude},${add.longitude}`)
          .join('|');

        const startLat = position.coords.latitude;
        const startLong = position.coords.longitude;
        const params = {
          origin: `${startLat},${startLong}`,
          waypoints: waypoints,
        };

        mapApi.directions(params).then((response) => {
          if (response) {
            const optimizedWaypoints = response.data;

            setOpenDirectionModal(false);

            if(optimizedWaypoints.length === 1){
              alert('No route found');
              
              return;
            }

            let optimizedLocations = optimizedWaypoints
              .map((index) => addresses[index])
              .slice(1);

            if (direction === 2) {
              optimizedLocations = optimizedLocations.reverse();
            }

            const optimizedWaypointString = optimizedLocations
              .slice(0, optimizedLocations.length - 1)
              .map((loc) => `${loc.latitude},${loc.longitude}`)
              .join('|');

            const destAddress1 =
              optimizedLocations[optimizedLocations.length - 1];
            const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&origin=${startLat},${startLong}&destination=${destAddress1.latitude},${destAddress1.longitude}&waypoints=${optimizedWaypointString}`;

            const popup = window.open(googleMapsUrl, '_blank');
            
            if (!popup) {
              alert("Pop-up blocked! Redirecting to Google Maps in the same tab.");
              window.location.href = googleMapsUrl; // Fallback to same tab if pop-up is blocked
            }
          }
        });
      }
       catch (err) {
        if (err.code === err.PERMISSION_DENIED) {
          alert(
            'You have denied location access. Please enable location services to proceed.',
          );
          alert(
            'To enable location, go to your browser settings or device settings.',
          );
        } else if (err.code === err.POSITION_UNAVAILABLE) {
          alert(
            "Location information is unavailable. Please check your device's connection.",
          );
        } else if (err.code === err.TIMEOUT) {
          alert('Location request timed out. Please try again.');
        } else {
          alert('An unknown error occurred while requesting location.');
        }
      }

  };
  


  return (
    <>
      <div className="open-findings" ref={widgetRef}>
        <header className="open-findings__header" style={widgetWidth > 700 ? oneColumnHeader : null}>
          <h2 className="open-findings__title">Open Findings</h2>
          <Tooltip
            title={'Click to refresh data/results'}
            placement='top'
            arrow
          >
            <h5 className="open-findings__reload-button" onClick={() => fetchStatsFuncForOF.refetch()}>
              <FontAwesomeIcon icon={faRedoAlt} />
            </h5>
          </Tooltip>
          <div className="open-findings__direction">
              {selectedProjects.length > 0 && (
                <button className="primary" onClick={()=>setOpenDirectionModal(true)} >Directions</button>
              )}
          </div>
          <div className="open-findings__bars">
            <select name="finding-type" id="finding-type" placeholder="Select" onChange={(event) => handleSelect(event)}>
              <option value=" ">All Findings</option>
              {findingTypeListForOF.map((ele) => {
                return (
                  ele === "CA" ? <option value={ele}>Corrective Action</option> :
                    ele === "MI" ? <option value={ele}>Maintenance Item</option> :
                      <option value={ele}>{ele}</option>
                )
              })}
            </select>
            <input onChange={(event) => setSearch(event.target.value)} placeholder="Search" />
          </div>
          {/* <div className="open-findings__ellipsis-button">
            <span onClick={(event) => setAnchorEl(event.currentTarget)}>
              <FontAwesomeIcon icon={faEllipsisV} />
            </span>
          </div> */}
          <EllipsisPopover handleCloseFunc={handleClose} id={id} open={open} anchorEl={anchorEl} />

        </header>

        <div
          data-rbd-drag-handle-context-id={providedProps}
          data-rbd-drag-handle-draggable-id="gibberish"
          style={{
            // When you set the data-rbd-drag-handle-context-id, RBD applies cursor: grab, so we need to revert that
            cursor: "auto"
          }}
        >
          <div className="open-findings__body">
            <div className="open-findings__content">
              <section className="open-findings__table-wrapper">
                <div className="open-findings__table-left">
                  <div className="open-findings__table-head">
                    <h3 onClick={sortBySiteName}>Site Name <span><FontAwesomeIcon icon={faSort} /></span></h3>
                    <h3 onClick={sortByClientName}>Client Name <span><FontAwesomeIcon icon={faSort} /></span></h3>
                  </div>

                  {
                    !isLoadingForOF ?
                      (displayStatsForOF && displayStatsForOF.map((ele, index) => {
                        return (
                          <div key={index} className="open-findings__table-body">
                            <input
                            type="checkbox"
                            checked={selectedProjects.find(selectedProject=>selectedProject.id === ele.projectId)}
                            onChange={() => handleCheckboxChange({id:ele.projectId,latitude:ele.latitude,longitude:ele.longitude})}
                            disabled={selectedProjects.length ===10 && !selectedProjects.find(selectedProject=>selectedProject.id === ele.projectId) ? true:false}
                            />
                            <li>{ele.siteName}</li>
                            <li>{ele.clientName}</li>
                          </div>
                        )
                      })) 
                      :
                      (<Loading what="data" />)
                  }
                </div>

                <div className="open-findings__table-right">
                  <div className="open-findings__table-right-inner-wrapper">
                    <div className="open-findings__table-head">
                      <h3>Open Findings </h3>
                      <h3>Max Days Opened </h3>
                      <h3>Action</h3>
                    </div>

                    {
                      !isLoadingForOF ?
                        (displayStatsForOF && displayStatsForOF.map((ele, index) => {
                          return (
                            <div key={index} className="open-findings__table-body">
                              <li>{ele.openFindingsCount}</li>
                              <li>{ele.maxDaysOpened}</li>
                              <li>
                                <Link to={`/divisions/${ele.divisionId}/projects/${ele.projectId}/findings`}>
                                  <span>View Project</span>
                                </Link>
                              </li>
                            </div>
                          )
                        })) :
                        (<Loading what="data" />)
                    }
                  </div>
                </div>
              </section>
            </div>

            <footer className="open-findings__footer">
              <span></span>
              <span onClick={changeShowMore}>Show {(mdSize === 6) ? 'More' : 'Less'} <FontAwesomeIcon icon={faExternalLinkAlt} /></span>
              {selectedProjects.length? <span>  Selected {selectedProjects.length} projects {selectedProjects.length===10 && '(max)'} </span>: <span></span> }
            </footer>
          </div>
        </div>
      </div>
      {openDirectionModal &&   
        <FormContextProvider>
        <DirectionModal Submit={(direction)=>GetDirection(direction)} onClose={()=>setOpenDirectionModal(false)} />
       </FormContextProvider>
        }
    </>
  )
}


export default OpenFindings