import xhrService from "../xhrService";
import { Address } from "./address";
import { Client, Contact } from "./client";
const baseUrl = "/api/projects";

/**
 * Finish types
 *
 */
export type Project = {
  id: number;
  name: string;
  client_id: number;
  address_id: number;
  inspection_template_id: number;
  permittee?: string;
  notes?: string;
  timezone: string;
  document_group: number;
  start_date: string;
  end_date: string;
  inspection_compliance_required: boolean;
  cgp_tracking_id?: number;
  next_inspection_date: string;
  archivedAt?: string;
  findingComplianceRequired: boolean;
  isCcrProject: boolean;
  address?: Address;
  client?: Client;
  inspector_user_id?: number;
  createdAt: Date;
  project_type_id?: Number;
  precipitation_flag?: boolean;
  precipitation_threshold?: number;
  snow_threshold?: number;
  external_project?: boolean;
  dissolve_finding_images?: boolean;
  forecast_weather?: boolean;
  current_acreage?: number;
};

/**
 * Go through types
 */
type ProjectApi = {
  create: (data: any) => Promise<Project>;
  get: (id: number) => Promise<Project>;
  getDocs: (id: number, params?: any) => Promise<any>;
  getLog: (id: number) => Promise<any>;
  update: (id: number, data: any) => Promise<Project>;
  setInspectionDate: (id: number, data: { created_date: Date }) => Promise<any>;
  bulkStorm: {
    get: (projectIds: Array<number>) => Promise<any>;
    create: (data: any) => Promise<any>;
  };
  contacts: {
    index: (projectId: number) => Promise<Array<Contact>>;
    create: (projectId: number, userIds: Array<number>) => Promise<Contact>;
    destroy: (projectId: number, userId: number) => Promise<any>;
  };
  removeDuplicateLayers: (id: number) => Promise<any>;
  getProjectLegendItemLog: (id: number, legendItemId: number, showFull: boolean) => Promise<any>;
  getNotes: (id: number, showFull: boolean) => Promise<any>
  getProjectTypes: (tenantId: number) => Promise<any>
  updateInspectionIntervalId: (projectId: number, defaultIntervalId: any) => any;
  getHourlyPrecipitationData: (id: number, paramsObject: any) => Promise<any>;
  getDailyPrecipitationData: (id: number, paramsObject: any) => Promise<any>;
  updateOneHourlyPrecipitationEntry: (id: number, updatedData: any) => Promise<any>;
  updateOneDailyPrecipitationEntry: (id: number, updatedData: any) => Promise<any>;
  documentsLog: {
    getDocumentsLog: (projectId: number | null, filterType?: any, page?: any, rowsPerPage?: any, search?: any) => Promise<any>;
    restore: (projectId: number | null, projectUploadId: number | null, data: any) => Promise<any>;
  }
  getFindingsReport: (projectId: number | null, data: any) => Promise<any>;
  getNOAAWeatherData: (id: number, paramsObject: any) => Promise<any>;
  sortDocs: (data: any) => Promise<any>;
};

export const projectApi: ProjectApi = {
  create: data => {
    return xhrService.post(baseUrl, data).then(response => response.data);
  },
  get: (id: number) => {
    return xhrService.get(`${baseUrl}/${id}`).then(response => response.data);
  },
  /**
   * @return {Promise<{docTypes: any[], regulations: any[], document_group_name: string}>}
   */
  getDocs: (id: number, doctype?: any) => {
    const params: { doctype?: any } = {}

    if (doctype) {
      params.doctype = doctype
    }

    return xhrService
      .get(`${baseUrl}/${id}/docs`, { params })
      .then(response => response.data);
  },
  getLog: (id: number) => {
    return xhrService
      .get(`/api/changelog/projects/frontendChangelogs/${id}`)
      .then(response => response.data);
  },
  update: (id: number, data) => {
    return xhrService
      .put(`${baseUrl}/${id}`, data)
      .then(response => response.data)
      .catch(error => Promise.reject(error.response.data));
  },
  /**
   * Change the due date of the next inspection
   *
   * @param {number} id Primary ket of project
   * @param {{ created_date: string }} data Form data
   */
  setInspectionDate: (id: number, data: { created_date: Date }) => {
    return xhrService
      .put(`${baseUrl}/${id}/attr/next_inspection_date`, {
        value: data.created_date,
      })
      .then(response => response.data)
      .catch(error => Promise.reject(error.response.data));
  },
  bulkStorm: {
    get: (projectIds: Array<number>) => {
      return xhrService
        .get("/api/projects/post-storm", { params: { projectIds } })
        .then(res => res.data);
    },
    create: data => {
      return xhrService
        .post("/api/projects/post-storm", { data })
        .then(res => res.data);
    },
  },

  contacts: {
    index: (projectId: number) =>
      xhrService.get(`${baseUrl}/${projectId}/contacts`).then(res => res.data),
    create: (projectId: number, userIds: Array<number>) =>
      xhrService
        .post(`${baseUrl}/${projectId}/contacts`, { userIds })
        .then(res => res.data),
    destroy: (projectId: number, userId: number) =>
      xhrService
        .delete(`${baseUrl}/${projectId}/contacts/${userId}`)
        .then(res => res.data),
  },

  removeDuplicateLayers: (id: number) => {
    return xhrService.post(`${baseUrl}/${id}/removeDuplicateLayers`).then(response => response.data);
  },

  getProjectLegendItemLog: (id: number, legendItemId: number, showFull: boolean) => {
    return xhrService
      .get(`/api/changelog/projects/${id}/legendItem/${legendItemId}`, {
        params: {
          showFull: showFull
        }
      })
      .then(response => response.data);
  },

  getProjectTypes: () => {
    return xhrService
      .get(`/api/project-types?isIncluded=true`)
      .then(res => res.data);
  },

  getNotes: (id: number, showFull: boolean) => {
    return xhrService
      .get(`/api/changelog/projects/${id}/getNotes`, {
        params: {
          showFull: showFull
        }
      })
      .then(response => response.data);
  },

  updateInspectionIntervalId: (projectId: number, defaultIntervalId: number) => {
    return xhrService.put(`${baseUrl}/${projectId}/updateInspectionIntervalId`, {defaultIntervalId: defaultIntervalId}).then(res => res.data)
  },

  getHourlyPrecipitationData: (id: number, paramsObject: any) => {
    return xhrService.get(`${baseUrl}/${id}/hourlyPrecipitationLog`, {
      params: paramsObject
    }).then(response => {
      return response.data
    });
  },

  getDailyPrecipitationData: (id: number, paramsObject: any) => {
    return xhrService.get(`${baseUrl}/${id}/dailyPrecipitationLog`, {
      params: paramsObject
    }).then(response => {
      return response.data
    });
  },

  updateOneHourlyPrecipitationEntry: (projectId: number, updatedData: any) => {
    return xhrService.put(`${baseUrl}/${projectId}/updateOneHourlyPrecipitationEntry/${updatedData.id}`, {updatedData: updatedData}).then(res => res.data)
  },

  updateOneDailyPrecipitationEntry: (projectId: number, updatedData: any) => {
    return xhrService.put(`${baseUrl}/${projectId}/updateOneDailyPrecipitationEntry/${updatedData.id}`, {updatedData: updatedData}).then(res => res.data)
  },

  documentsLog: {
    getDocumentsLog: (projectId: number | null, filterType?: any, page?: any, rowsPerPage?: any, search?: any) => {
      let paramsObj = search === '' ? 
        {
          p: page,
          rows: rowsPerPage,
          filter: filterType
        } :
        {
          search,
          filter: filterType
        }

      return xhrService.get(`${baseUrl}/${projectId}/getDocumentsLog`, { params: paramsObj })
      .then(res => {
        return res.data
      })
    },
    restore: (projectId: number | null, projectUploadId: number | null, data: any) => {
      return xhrService.put(`${baseUrl}/${projectId}/documentsLog/restore/${projectUploadId}`, data).then(res => {
        return res.data
      })
    }
  },

  getFindingsReport: (projectId: number | null, data: any) => {
    return xhrService
      .get(`${baseUrl}/${projectId}/getFindingsReport`, {
        params: data
      })
      .then(response => response.data);
  },

  getNOAAWeatherData: (id: number, paramsObject: any) => {
    return xhrService.get(`${baseUrl}/${id}/noaaWeatherTable`, {
      params: paramsObject
    }).then(response => {
      return response.data
    });
  },

  sortDocs: (data: any) => {
    return xhrService.post(`${baseUrl}/documents/sort`, { data })
    .then(response => {
      return response.data
    });
  },
};

export default projectApi;
