import React, { useContext } from 'react';
import RolesContext from '../../contexts/RolesContext';
import { ActionButtons } from '../Shared/ActionButtons';
import { useHistory } from 'react-router-dom';

export interface ISiteMapCard {
  imgSrc: string;
  title: string;
  label: string;
  handleImgClick?: () => void;
  handleDelete?: () => void;
  handleRename?: () => void;
  handleReplace?: () => void;
  handleArchive?: () => void;
  handlePrint?: () => void;
  handleDownload?: () => void;
  canArchive?: boolean;
  canDelete?: boolean;
  canRename?: boolean;
  canReplace?: boolean;
  isArchive?: boolean;
  isLoading?: boolean;
}

const SiteMapCard: React.FC<ISiteMapCard> = ({
  imgSrc,
  title,
  label,
  handleImgClick,
  handleDelete,
  handleRename,
  handleReplace,
  handleArchive,
  handlePrint,
  handleDownload,
  canArchive,
  isArchive,
  isLoading,
}) => {
  const permCheck = useContext(RolesContext).userHasPermission;
  const canUpdate = permCheck('update', label);
  const canPrint = permCheck('all', 'Download');
  const history = useHistory();
  const isPublic = history.location.pathname.startsWith("/public")

  const secondaryActions = [];

  if (canUpdate) {
    secondaryActions.push(
      {
        label: 'Rename',
        icon: 'pencil',
        onClick: handleRename,
      },
      {
        label: 'Replace',
        icon: 'exchange',
        onClick: handleReplace,
      },
      {
        label: 'Delete',
        icon: 'trash',
        onClick: handleDelete,
      },
    );
  }
  
  if(canUpdate && canPrint){
    secondaryActions.push({
      label: 'Download',
      icon: 'download',
      onClick: handleDownload,
    });
  }

  if (canArchive) {
    secondaryActions.push({
      label: isArchive ? 'Restore' : 'Archive',
      icon: isArchive ? 'undo' : 'archive',
      onClick: handleArchive,
    });
  }

  return (
    <section
      className='card'
      style={{
        position: 'relative',
      }}
    >
      {isLoading ? (
        <div className='card-loader'>
          <div className='loader' />
        </div>
      ) : (
        (!isPublic)?
        <ActionButtons
          secondary={secondaryActions}
          className='card-active-site-map-menu-button'
        />
        :
        <></>
      )}
      <div
        className='card-img card-cap'
        style={{
          backgroundImage: `url(${imgSrc})`,
          cursor: handleImgClick && canUpdate ? 'pointer' : 'initial',
        }}
        title={title}
        onClick={() => (handleImgClick && canUpdate ? handleImgClick() : null)}
      />

      <div className='card-body'>
        <p className='card-title'>{title}</p>
      </div>

      <div className='card-body-actions card-actions'>
        <button
          className='card-action pure-button button-primary'
          onClick={handleImgClick}
        >
          {canUpdate && canArchive ? 'Update Site Map' : 'View Site Map'}
        </button>

        {canPrint && (
          <button
            className='card-action pure-button button-outline-blue '
            onClick={handlePrint}
          >
            Print Site Map
          </button>
        )}
      </div>
    </section>
  );
};

export default SiteMapCard;
