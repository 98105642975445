import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography, Popover } from '@mui/material';
// import { faEyeSlash, faExpand } from '@fortawesome/free-solid-svg-icons';



type Props = {
    handleCloseFunc: any,
    id: any,
    open: any,
    anchorEl: any,
    thirdOption?: {
        sx: any,
        icon: any,
        optionName: any,
        handleOnClickFunc: Function
    },
    forthOption?: {
        sx: any,
        icon: any,
        optionName: any,
        handleOnClickFunc: Function
    }
}



const EllipsisPopover = ({ handleCloseFunc, id, open, anchorEl, ...props } : Props) => {
    const handleClose = () => {
        handleCloseFunc(null);
    }

    const handleClick=()=>{
        if(props.thirdOption && props.thirdOption.handleOnClickFunc)
        props.thirdOption.handleOnClickFunc()
    }

    const handleForthOptionClick=()=>{
        if(props.forthOption && props.forthOption.handleOnClickFunc)
        props.forthOption.handleOnClickFunc()
    }

    return(
        <>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                PaperProps={{
                    style: { width: '13rem', padding: '1rem' },
                }}
            >
                {/* <Typography sx={{ p: 1 }} className={`widget-popover-items`}> <FontAwesomeIcon icon={faEyeSlash} size={'sm'} /><p>Hide Widget</p></Typography>
                <Typography sx={{ p: 1 }} className={`widget-popover-items`}> <FontAwesomeIcon icon={faExpand} size={'sm'} /><p>Show Less</p> </Typography> */}
                { props.thirdOption &&
                    <Typography sx={props.thirdOption.sx} className={`widget-popover-items`} onClick={() => handleClick()}>
                        <FontAwesomeIcon icon={props.thirdOption.icon} size={'sm'}/>
                        <p>{props.thirdOption.optionName}</p>
                    </Typography>               
                }
                { props.forthOption &&
                    <Typography sx={props.forthOption.sx} className={`widget-popover-items`} onClick={() => handleForthOptionClick()}>
                        <FontAwesomeIcon icon={props.forthOption.icon} size={'sm'}/>
                        <p>{props.forthOption.optionName}</p>
                    </Typography>
                }
            </Popover>
        </>
    )
}



export default EllipsisPopover
