import React, {
  useContext,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from 'react';
import userApi from '../../../utils/api/user';
import clientApi from '../../../utils/api/client';
import divisionApi from '../../../utils/api/division';
import { flatten, cloneDeep, pick, get } from 'lodash';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { FormContext, SchemaFieldsWithContextUI } from '@sw-sw/lib-form';
import { UIControlType } from '@sw-sw/lib-form-control-types';
import Pagination from 'react-js-pagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useUserFormData } from '../../../hooks/userFormData';
import { AppDivisionContext } from '../../../contexts/AppDivisionContext';

import {
  faAngleUp,
  faAngleDown,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import Switch from '@mui/material/Switch';
import xhrService from '../../../utils/api';
import ConfirmationModal from '../../Shared/ConfirmationModal/ConfirmationModal';
import tenantApi from '../../../utils/api/tenant';

const roleNameToManagerTitle = {
  Inspector: 'Managers',
  'Area / Assistant Manager': 'Regional Manager',
};

const tenantUserFormSchema = (roles, divisions, managers) => {
  const sch = {};

  const formContext = useContext(FormContext);

  const selectedRole = roles
    ? roles.find((v) => v.id === formContext.value.roleId)
    : null;

  const selectedRole1 = selectedRole
    ? selectedRole.name
    : formContext.initialValue.roleName
      ? formContext.initialValue.roleName
      : null;

  sch.roleId = {
    label: 'Role',
    controlType: UIControlType.select,
    options: roles,
    labelKey: 'name',
    valueKey: 'id',
    validation: {
      required: true,
    },
    style: {
      flexBasis: '50%',
    },
    parse: (val) => Number.parseInt(val, 10),
  };
  sch.divisionIds = {
    controlType: UIControlType.customTagSelect,
    labelKey: 'name',
    valueKey: 'id',
    isMulti: true,
    openOnFocus: true,
    label: 'Divisions',
    options: divisions,
    noOptionMessage: 'No Divisions found',
    placeholder: 'Search for Divisions',
    validation: {
      required: true,
    },
    style: {
      flexBasis: '50%',
      maxWidth: '50%',
    },
  };

  if (selectedRole1 && roleNameToManagerTitle[selectedRole1]) {
    sch.managerUserIds = {
      label: `Assign to ${roleNameToManagerTitle[selectedRole1]}`,
      controlType: UIControlType.customTagSelect,
      options: managers,
      isMulti: true,
      openOnFocus: true,
      labelKey: 'name',
      valueKey: 'id',
      style: {
        flexBasis: '50%',
        maxWidth: '50%',
      },
    };
  }

  return sch;
};

const TenantUserFormUi = ({
  formOpts,
  multiTenantData,
  setMultiTenantData,
  tenantId,
}) => {
  const formContext = useContext(FormContext);

  useEffect(() => {
    if (formContext.value.roleId && formContext.value.divisionIds) {
      setMultiTenantData((prev) => {
        if (prev[tenantId]) {
          prev[tenantId] = {
            ...prev[tenantId],
            divisionIds: formContext.value.divisionIds,
            roleId: formContext.value.roleId,
            managerUserIds: formContext.value.managerUserIds,
          };
        }

        return prev;
      });
    }
  }, [formContext.value]);

  useEffect(() => {
    if (formContext.value.roleId && formOpts.roles) {
      const roleIdArr = [];

      formOpts.roles.forEach((ele) => {
        if (['Area / Assistant Manager', 'Inspector'].includes(ele.name)) {
          roleIdArr.push(ele.id);
        }
      });

      if (!roleIdArr.includes(formContext.value.roleId)) {
        formContext.set('managerUserIds', null);
      }
    }
  }, [formContext.value.roleId]);

  return (
    <SchemaFieldsWithContextUI
      schema={tenantUserFormSchema(
        formOpts.roles,
        formOpts.divisions,
        formOpts.managers,
      )}
      initialFormData={multiTenantData[tenantId] || {}}
      formData={multiTenantData[tenantId]}
    ></SchemaFieldsWithContextUI>
  );
};

const TenantUserTab = ({
  tenantId,
  userId,
  multiTenantData,
  setMultiTenantData,
  roles,
}) => {
  const formContext = useContext(FormContext);

  const [formOpts, setFormOpts] = useState(null);

  const appDivisionContext = useContext(AppDivisionContext);

  const formDataQuery =
    appDivisionContext.appDivisionId &&
    useUserFormData(appDivisionContext.appDivisionId);

  const [formQuery] = useState({
    roles: formDataQuery.roles.data,
  });

  const itemCount = 7;
  const [startItem, setStartItem] = useState(0);
  const [lastItem, setLastItem] = useState(itemCount);
  const [activePage, setActivePage] = useState(1);
  const [clientId, setClientId] = useState(null);
  const [initialValue, setInitialValue] = useState({
    tags: [],
    roleId: '',
    projects: [],
  });
  const [showDeleteClientModal, setShowDeleteClientModal] = useState(false);
  const [tableData, setTableData] = useState([]);

  const [searchInput, setSearchInput] = useState('');
  const [options, setOptions] = useState('');

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [searchTableData, setSearchTableData] = useState([]);

  const animatedComponents = makeAnimated();

  const [renderSearchData, setRenderSearchData] = useState(false);

  useEffect(() => {
    userApi.getUserByTenant(userId, tenantId).then(async (res) => {
      tenantApi.divisions
        .index(tenantId)
        .then((data) => {
          setFormOpts({
            ...formOpts,
            divisions: data.length ? data : [],
            roles: formQuery.roles,
          });

          setMultiTenantData((prev) => {
            if (!prev[tenantId]) {
              prev[tenantId] = {
                divisionIds: res.divisions,
                projects: res.projects,
                roleId: res.roleId,
                clients: res.clients,
                managerUserIds: res.managers,
              };

              formContext.set('divisionIds', res.divisions);
              formContext.set('roleId', res.roleId);
              if (res.managers.length)
                formContext.set('managerUserIds', res.managers);
              setTableData(createTableData(extractInitialValue(res)));
              setInitialValue(extractInitialValue(res));
            } else {
              setTableData(createTableData(prev[tenantId]));
              setInitialValue(prev[tenantId]);
            }

            return prev;
          });
        })
        .catch((err) => {
          console.log(err);
        });
    });

    // clean up function
    return () => {
      setFormOpts(null);
      setInitialValue({});
      setTableData([]);
      setOptions([]);
      setRenderSearchData(false);
      setSearchTableData([]);
    };
  }, [tenantId]);

  useEffect(async () => {
    if (formContext.value.divisionIds && formContext.value.roleId) {
      const divisionIds = formContext.value.divisionIds
        ? formContext.value.divisionIds.map((_) => _.id)
        : [];

      return userApi
        .getCreateOpts({
          divisionIds: divisionIds,
          roleId: formContext.value.roleId,
          tenantId: tenantId,
        })
        .then((data) => {
          setFormOpts({
            ...formOpts,
            managers: data.managers,
          });

          formContext.setBusy(false);
        });
    }
  }, [formContext.value.divisionIds, formContext.value.roleId]);

  useEffect(async () => {
    if (formContext.value.divisionIds && formContext.value.divisionIds.length) {
      const clientOption = await Promise.all(
        formContext.value.divisionIds.map((ele) => {
          return divisionApi.clients.index(ele.id).then(async (res) => {
            const allOption = getOptions(res);
            const existingOption = getOptions(initialValue.clients);
            const newOption = allOption.filter((val) => {
              return !existingOption.includes(val);
            });

            return newOption;
          });
        }),
      );

      const newOpt = await userApi
        .getUserByTenant(userId, tenantId)
        .then(async (result) => {
          const clientArr = getOptions(result.clients);

          const updatedOption = flatten(clientOption).filter((val) => {
            return !clientArr.some((ele) => ele.value === val.value);
          });

          return updatedOption;
        });

      setOptions(flatten(newOpt));
    } else {
      setOptions([]);
    }
  }, [formContext.value.divisionIds]);

  useEffect(() => {
    if (searchTableData.length > 0 && !renderSearchData) {
      setRenderSearchData(true);
    }
  }, [searchTableData]);

  function ExpandedView({ ele, clientName, onToggle }) {
    const label = { inputProps: { 'aria-label': 'Switch demo' } };

    return (
      <tr style={{ fontSize: '12px' }} className='client-detail-info-values'>
        <th colSpan={3}>{ele.name}</th>
        <th colSpan={3}>{ele.division}</th>
        <th colSpan={3} className='caret-cell'>
          <div>
            <Switch
              onChange={() => onToggle(ele.id)}
              {...label}
              checked={ele.toggle}
            />
          </div>
        </th>
        <th colSpan={3} className='caret-cell'>
          <Switch {...label} disabled defaultChecked={ele.toggle} />
        </th>
      </tr>
    );
  }

  function UserTable({
    user,
    index,
    handleRowClick,
    expanded,
    ...dashTableProps
  }) {
    const [projects, setProjects] = useState([]);
    const [archivedProjects, setArchivedProjects] = useState([]);
    const [accessAllProjects, setAccessAllProjects] = useState(false);

    useEffect(() => {
      if (projects.length !== user.projects.length) setProjects(user.projects);
      if (archivedProjects.length !== user.archivedProjects.length)
        setArchivedProjects(user.archivedProjects);
    }, [user.projects, user.archivedProjects]);

    useEffect(() => {
      userApi.getAutoAccessToProjects(userId, user.id).then((res) => {
        if (res) {
          setMultiTenantData((prev) => {
            if (prev[tenantId]) {
              prev[tenantId] = {
                ...prev[tenantId],
                clients: prev[tenantId].clients.map((ele) =>
                  ele.id === user.id
                    ? {
                        ...ele,
                        auto_access_to_projects: res.auto_access_to_projects,
                      }
                    : ele,
                ),
              };
            }

            return prev;
          });
          setAccessAllProjects(res.auto_access_to_projects);
        }
      });
    }, []);

    const handleAccessAllProjects = () => {
      if (!accessAllProjects) {
        const updatedProjects = projects.map((ele) => ({
          ...ele,
          toggle: true,
        }));

        setProjects(updatedProjects);

        setMultiTenantData((prev) => {
          if (prev[tenantId]) {
            prev[tenantId] = {
              ...prev[tenantId],
              projects: [...updatedProjects, ...prev[tenantId].projects],
            };
          }

          return prev;
        });
      }
      setMultiTenantData((prev) => {
        if (prev[tenantId]) {
          prev[tenantId] = {
            ...prev[tenantId],
            clients: prev[tenantId].clients.map((ele) =>
              ele.id === user.id
                ? {
                    ...ele,
                    auto_access_to_projects: !ele.auto_access_to_projects,
                  }
                : ele,
            ),
          };
        }

        return prev;
      });
      setAccessAllProjects(!accessAllProjects);
    };

    const handleToggle = (projectId) => {
      const updatedProjects = projects.map((project) =>
        project.id === projectId
          ? { ...project, toggle: !project.toggle }
          : project,
      );

      setProjects(updatedProjects);

      setMultiTenantData((prev) => {
        if (prev[tenantId]) {
          prev[tenantId] = {
            ...prev[tenantId],
            projects: [
              ...updatedProjects.filter((ele) => ele.toggle),
              ...prev[tenantId].projects.filter((ele) => ele.id !== projectId),
            ],
          };
        }

        return prev;
      });
    };

    const deleteClient = () => {
      setClientId(user.id);
      setShowDeleteClientModal(true);
    };

    const getDeleteAndExpand = () => {
      return (
        <>
          <FontAwesomeIcon
            style={{ marginRight: '10px' }}
            icon={faTrash}
            className='caret'
            onClick={() => deleteClient(index)}
          />
          <FontAwesomeIcon
            icon={expanded.includes(index) ? faAngleUp : faAngleDown}
            className='caret'
            onClick={() => handleRowClick(index)}
          />
        </>
      );
    };

    const tdStyle = {
      height: '50px',
      display: 'flex',
      justifyContent: 'center',
      textAlign: 'center',
    };

    const renderExpandedView = useMemo(
      () =>
        projects.map((ele) => (
          <ExpandedView
            key={ele.id}
            ele={ele}
            clientName={user.name}
            onToggle={handleToggle}
          />
        )),
      [projects],
    );

    const renderArchivedView = useMemo(
      () =>
        archivedProjects.map((ele) => (
          <ExpandedView
            key={ele.id}
            ele={ele}
            clientName={user.name}
            onToggle={handleToggle}
          />
        )),
      [archivedProjects],
    );

    return (
      <>
        <tr className='pointer client-basic-info'>
          <td colSpan={6} onClick={() => handleRowClick(index)}>
            {user.name}
          </td>
          <td className='caret-cell'>
            <td style={tdStyle}>{getDeleteAndExpand()}</td>
          </td>
        </tr>

        {expanded.includes(index) &&
          (projects.length || archivedProjects.length ? (
            <tr key={`expansion-${index}`}>
              <div className='access-all-projects-button-container-tenant-user-tab'>
                <Switch
                  onClick={handleAccessAllProjects}
                  checked={accessAllProjects}
                  defaultChecked={false}
                />
                <span style={{ display: 'flex', alignItems: 'center' }}>
                  Access All Projects
                </span>
              </div>
              <table
                colSpan={2}
                className='list-view pure-table pure-table-horizontal area-manager-table client-detail-info'
              >
                <thead className='list-header'>
                  <th colSpan={3}>Project Name</th>
                  <th colSpan={3}>Divisions</th>
                  <th colSpan={3}>Access to project</th>
                  <th colSpan={3}>Access to statistics</th>
                </thead>
                {projects.length ? <>{renderExpandedView}</> : null}
                {archivedProjects.length ? (
                  <>
                    <th>Archived Projects</th>
                    {renderArchivedView}
                  </>
                ) : null}
              </table>
            </tr>
          ) : (
            <tr key={`expansion-${index}`}>
              <table
                colSpan={2}
                className='list-view pure-table pure-table-horizontal area-manager-table'
              >
                <thead className='list-header'>
                  <th>No projects to be displayed</th>
                </thead>
              </table>
            </tr>
          ))}
      </>
    );
  }

  const Table = ({ data, tableKeys, getMenuOptions, getLink, props }) => {
    if (!data.length) {
      return (
        <section className='table-holder add-clients-table'>
          <table className='list-view pure-table pure-table-horizontal area-manager-table'>
            <thead className='list-header'>
              <tr>
                <th colSpan={6}>Client Name</th>
                <th colSpan={2}>Action</th>
              </tr>
            </thead>
            <tbody>
              <th>No Clients to be displayed</th>
            </tbody>
          </table>
        </section>
      );
    }
    const newData = [];
    const [expanded, setExpanded] = useState([]);

    data.length &&
      data.forEach((ele) => {
        const archived = [];
        const projects = ele.projects.map((project) => {
          if (project.archived) {
            archived.push(project);

            return null;
          } else {
            return project;
          }
        });

        newData.push({
          id: ele.id,
          name: ele.name,
          projects: projects.filter((project) => project),
          archivedProjects: archived,
        });
      });

    const handleRowClick = useCallback(
      (i) => {
        if (expanded.includes(i)) {
          const filterState = expanded.filter((item) => item !== i);

          setExpanded(filterState);
        } else {
          setExpanded([...expanded, i]);
        }
      },
      [expanded],
    );

    const onPageChange = (event) => {
      setLastItem(event * itemCount);
      setStartItem(event * itemCount - itemCount);
      setActivePage(event);
    };

    return (
      <section className='table-holder add-clients-table'>
        <table className='list-view pure-table pure-table-horizontal area-manager-table'>
          <thead className='list-header add-client-table-head'>
            <tr>
              <th colSpan={6}>Client Name</th>
              <th colSpan={2}>Action</th>
            </tr>
          </thead>
          <tbody>
            {newData.length &&
              newData.map((user, i) => {
                if (i >= startItem && i < lastItem) {
                  return (
                    <UserTable
                      tableKeys={tableKeys}
                      expanded={expanded}
                      handleRowClick={handleRowClick}
                      getMenuOptions={(args) =>
                        getMenuOptions({ ...args, getLink })
                      }
                      user={user}
                      index={i}
                      key={i}
                      {...props}
                    />
                  );
                }

                return null;
              })}
          </tbody>
        </table>
        <Pagination
          activePage={activePage}
          itemsCountPerPage={itemCount}
          totalItemsCount={data.length}
          pageRangeDisplayed={3}
          onChange={onPageChange}
        />
      </section>
    );
  };

  const getProjectData = (res) => {
    const newObj = [];

    const divisionIds =
      initialValue.divisionIds && initialValue.divisionIds.map((ele) => ele.id);

    res.forEach((projects) => {
      if (
        projects.divisionProject &&
        divisionIds.includes(projects.divisionProject.division_id)
      ) {
        newObj.push({
          id: projects.id,
          name: projects.name,
          division: divisionName(
            projects.divisionProject
              ? projects.divisionProject.division_id
              : null,
          ),
          toggle: false,
        });
      }
    });

    return newObj;
  };

  const divisionName = (id) => {
    let division = null;

    formOpts.divisions &&
      formOpts.divisions.forEach((ele) => {
        if (ele.id === id) {
          division = ele.name;
        }
      });

    return division;
  };

  const handleClientAdd = async () => {
    const clientIds = selectedOptions.map((ele) => ele.value);
    const getName = (id) => {
      let label = '';

      selectedOptions.forEach((ele) => {
        if (ele.value === id) {
          label = ele.label;
        }
      });

      return label;
    };

    const addedClient = await Promise.all(
      clientIds.map(async (id) => {
        const data = await clientApi.projects.index(id).then(async (res) => {
          return {
            id: id,
            name: getName(id),
            projects: getProjectData(res),
          };
        });

        return data;
      }),
    );
    const newOption = options.filter((val) => !selectedOptions.includes(val));
    const newClient = Object.assign(initialValue);

    addedClient.forEach((val) => {
      newClient.clients.push(val);
    });

    const newArr = [...tableData, ...addedClient];

    newArr.sort((a, b) => a.name.localeCompare(b.name));

    setTableData(newArr);
    setOptions(newOption);
    setInitialValue(newClient);
    setMultiTenantData((prev) => {
      if (prev[tenantId]) {
        prev[tenantId] = {
          ...prev[tenantId],
          clients: [...prev[tenantId].clients, ...addedClient],
        };
      }

      return prev;
    });
    setSelectedOptions(null);
  };

  const handleChangeSearch = (e) => {
    e.preventDefault();
    setSearchInput(e.target.value);
    setStartItem(0);
    setLastItem(itemCount);
    setActivePage(1);

    const newTableData = [];

    const searchData = cloneDeep(tableData);

    if (e.target.value.length > 1) {
      const sortClinet = searchData.map((table) => {
        if (table.name.toLowerCase().match(e.target.value.toLowerCase())) {
          newTableData.push(table);

          return null;
        }

        return table;
      });

      if (e.target.value.length > 2) {
        const projectSort = sortClinet.map((project) => {
          if (project) {
            const sortedProject = project.projects.filter((tableProject) => {
              return tableProject.name
                .toLowerCase()
                .match(e.target.value.toLowerCase());
            });

            project.projects = sortedProject;

            return project;
          }

          return null;
        });

        projectSort.forEach((project) => {
          if (project && project.projects.length) {
            newTableData.push(project);
          }
        });
      }
      setSearchTableData(newTableData);
    } else {
      setSearchTableData(tableData);
    }
  };

  const getOptions = (data) => {
    const optionData = [];

    data &&
      data.forEach((ele) => {
        optionData.push({
          label: ele.name,
          value: ele.id,
        });
      });

    return optionData;
  };

  const extractInitialValue = (user) => {
    const keys = [
      'id',
      'first_name',
      'last_name',
      'email',
      'address.phone',
      'address.phone_ext',
      'position',
      'roleId',
      'roleName',
      'ccrNumber',
      'peNumber',
    ];

    const mapAsOpts = (list) =>
      list.map((x) =>
        pick(x, ['id', 'name', 'client_id', 'divisionProject.division_id']),
      );

    return {
      ...pick(user, keys),

      /**
       * @important this is needed to initialize current selections
       */
      clients: mapAsOpts(get(user, 'clients', [])),
      projects: mapAsOpts(get(user, 'projects', [])),
      regulations: mapAsOpts(get(user, 'document_groups', [])),
      templates: mapAsOpts(get(user, 'templates', [])),
      divisionIds: mapAsOpts(get(user, 'divisions', [])),
      managerUserIds: mapAsOpts(get(user, 'managers', [])),
    };
  };

  const createTableData = (data) => {
    const clientArr = data.clients;
    const projectArr = data.projects.map((ele) => ele.id);
    const divisionIds = data.divisionIds.map((ele) => ele.id);

    const newArr = [];

    clientArr.map(async (ele) => {
      const newObj = {
        id: ele.id,
        name: ele.name,
        projects: [],
      };

      xhrService.get(`/api/clients/${ele.id}/projects`).then((res) => {
        res.data.forEach((projects) => {
          if (
            projects.divisionProject &&
            divisionIds.includes(projects.divisionProject.division_id)
          ) {
            newObj.projects.push({
              id: projects.id,
              name: projects.name,
              // division: divisionName(
              //   projects.divisionProject
              //     ? projects.divisionProject.division_id
              //     : null,
              // ),
              toggle: projectArr.includes(projects.id),
              archived: projects.archivedAt ? true : false,
            });
          }
        });
        newObj.projects.sort((a, b) => a.name.localeCompare(b.name));
      });

      newArr.push(newObj);
    });
    newArr.sort((a, b) => a.name.localeCompare(b.name));

    return newArr;
  };

  return (
    <div>
      {formOpts && !formOpts.loading && formOpts.divisions.length && (
        <React.Fragment>
          <TenantUserFormUi
            tenantId={tenantId}
            formOpts={formOpts}
            multiTenantData={multiTenantData}
            setMultiTenantData={setMultiTenantData}
          />

          <div className='searchContainer'>
            <input
              type='text'
              placeholder='Search here'
              onChange={handleChangeSearch}
              value={searchInput}
            />
            <section className='addClientMainContainer'>
              <div className='addClientContainer'>
                <p className='addClientText'>Add Clients</p>
                <div className='selectClient'>
                  <Select
                    closeMenuOnSelect={false}
                    isMulti
                    components={animatedComponents}
                    options={options}
                    onChange={(data) => {
                      setSelectedOptions(data);
                    }}
                    isClearable={true}
                    value={selectedOptions}
                    id={'selectInput'}
                  />
                  <button className='primary' onClick={() => handleClientAdd()}>
                    Save Client
                  </button>
                </div>
              </div>
            </section>
          </div>
          {renderSearchData && tableData ? (
            <Table data={searchTableData} />
          ) : (
            <Table data={tableData} />
          )}

          <ConfirmationModal
            title={`Are you sure you want to delete this client?`}
            show={showDeleteClientModal}
            handleClose={() => setShowDeleteClientModal(false)}
            handleConfirm={(event) => {
              let arrIndex = null;
              const arrClientIndex = [];
              const projectId = [];

              tableData.forEach((ele, i) => {
                if (ele.id === clientId) {
                  arrIndex = i;
                  if (ele.projects.length) {
                    ele.projects.map((val) => projectId.push(val.id));
                  }
                }
              });
              const newInitalValue = Object.assign(initialValue);
              const newTableData = Object.assign(tableData);

              newInitalValue.clients.forEach((ele, i) => {
                if (ele.id === clientId) {
                  arrClientIndex.push(i);
                }
              });

              const projectIndex = newInitalValue.projects.map((val, i) => {
                if (projectId.includes(val.id)) {
                  return i;
                }

                return null;
              });

              projectIndex.filter((item) => item);

              projectId.length &&
                projectIndex.forEach((ele) => {
                  newInitalValue.projects.splice(ele, 1);
                });
              arrClientIndex.length &&
                arrClientIndex.forEach((val) => {
                  newInitalValue.clients.splice(val, 1);
                });
              newTableData.splice(arrIndex, 1);
              setTableData(newTableData);
              setMultiTenantData((prev) => {
                if (prev[tenantId]) {
                  prev[tenantId] = {
                    ...prev[tenantId],
                    clients: newInitalValue.clients,
                    projects: newInitalValue.projects,
                  };
                }

                return prev;
              });

              setShowDeleteClientModal(false);
              setInitialValue(newInitalValue);
            }}
            buttonText='Delete client'
          />
        </React.Fragment>
      )}
    </div>
  );
};

export default TenantUserTab;
